import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import './Sidebar.scss';
import packageJson from '../../../package.json';
import axios from 'axios';
import dataService from '../Common/Services/dataService';
import { useState, useEffect } from 'react';
import { authService } from '../..';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    IconButton
} from '@mui/material';
import { AccountCircle, AutoFixHigh, Menu, PowerSettingsNew } from '@mui/icons-material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SendIcon from '@mui/icons-material/Send';
import DescriptionIcon from '@mui/icons-material/Description';
import PowerIcon from '@mui/icons-material/Power';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BuildIcon from '@mui/icons-material/Build';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

type ApiVersion = {
    version: string;
    environment: string;
    dry_run: boolean;
};

const Sidebar = ({ ...props }) => {
    const [sidebarIsExpanded, setSidebarIsExpanded] = useState<boolean>(true);
    //eslint-disable-next-line
    const { staticContext, ...rest } = props;
    const [apiVersion, setApiVersion] = useState<ApiVersion>();
    const [showUiVersion, setShowUiVersion] = useState<boolean>();
    const appVersion = packageJson?.version;
    const [elementHovered, setElementHovered] = useState<string>();
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const location = useLocation();
    const [codec, setCodec] = useState<string>();

    useEffect(() => {
        // Load expanded menu pref stored in localstorage
        const sidebarIsExpanded = JSON.parse(localStorage.getItem('sidebarIsExpanded') || 'true');
        if (typeof sidebarIsExpanded === 'boolean') {
            setSidebarIsExpanded(sidebarIsExpanded);
            setExpandedCssClasses(sidebarIsExpanded);
        }

        axios.get('/api/version/').then((response) => {
            setApiVersion(response?.data);
        });

        let encoderSpecs = dataService.getData('encodersSpecs');
        setCodec(`${encoderSpecs?.commit} (${encoderSpecs?.version})`);
    }, []);

    const toggleBurger = () => {
        localStorage.setItem('sidebarIsExpanded', !sidebarIsExpanded + '');
        setExpandedCssClasses(!sidebarIsExpanded);
        setSidebarIsExpanded(!sidebarIsExpanded);
    };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const setExpandedCssClasses = (menuIsExpanded: boolean) => {
        if (menuIsExpanded) {
            document.body.classList.add('sidebar-expanded');
            document.body.classList.remove('sidebar-collapsed');
        } else {
            document.body.classList.remove('sidebar-expanded');
            document.body.classList.add('sidebar-collapsed');
        }
    };

    const handleHoverOff = () => {
        setElementHovered('');
    };

    const handleHoverOn = (activeKey: string) => {
        setElementHovered(activeKey);
    };

    const sidebarItems = [
        authService.canAccess('PURPOSE_MENU') && {
            item: (
                <>
                    <UnarchiveIcon fontSize="small" /> <span>Intentions</span>
                </>
            ),
            key: '/purposes',
            children: [
                authService.canAccess('PURPOSE_CONFIGURATION') && {
                    item: <Link to="/campaigns/configuration">Configuration</Link>,
                    key: 'configuration'
                },
                authService.canAccess('PURPOSE_AFFILIATION') && {
                    item: <Link to="/campaigns/affiliation">Affiliation</Link>,
                    key: 'affiliation'
                },
                authService.canAccess('PURPOSE_CLOCK_SYNCHRO') && {
                    item: <Link to="/campaigns/clock-synchro">Synchro d'horloge</Link>,
                    key: 'clock-synchro'
                },
                authService.canAccess('PURPOSE_GUTERMANN') && {
                    item: <Link to="/campaigns/gutermann">Gutermann</Link>,
                    key: 'gutermann'
                },
                authService.canAccess('PURPOSE_CUSTOM') && {
                    item: <Link to="/campaigns/custom">Custom</Link>,
                    key: 'custom'
                },
                authService.canAccess('PURPOSE_BROADCAST') && {
                    item: <Link to="/campaigns/broadcast">Broadcast</Link>,
                    key: 'broadcast'
                },
                authService.canAccess('PURPOSE_RAW') && {
                    item: <Link to="/campaigns/raw">Raw</Link>,
                    key: 'raw'
                }
            ]
        },
        authService.canAccess('SENTLIST_MENU') && {
            item: (
                <>
                    <SendIcon fontSize="small" /> <span>Envois</span>
                </>
            ),
            key: '/sendings',
            children: [
                {
                    item: <Link to="/sendings/sent-dashboard">Tableau de bord</Link>,
                    key: 'sent-dashboard'
                },
                {
                    item: <Link to="/sendings/sent-list?tab=campaigns">Récapitulatif</Link>,
                    key: 'sent-list'
                },
                {
                    item: <Link to="/sendings/archived-list">Archivés</Link>,
                    key: 'archived-list'
                }
            ]
        },
        {
            item: (
                <>
                    <ScheduleSendIcon fontSize="small" /> <span>Envois étalés</span>
                </>
            ),
            key: 'spread-sendings',
            children: [
                {
                    item: (
                        <Link to="/spread-sendings/concentrator-statuses">
                            Statuts des concentrateurs
                        </Link>
                    ),
                    key: 'concentrator-statuses'
                },
                {
                    item: <Link to="/spread-sendings/hr-sendings">Envois HR</Link>,
                    key: 'hr-sendings'
                }
            ]
        },
        authService.canAccess('TEMPLATE_MENU') && {
            item: (
                <>
                    <DescriptionIcon fontSize="small" /> <span>Modèles</span>
                </>
            ),
            key: '/models',
            children: authService.canAccess('TEMPLATE_PARAMETERS_MENU') && [
                {
                    item: <Link to="/models">Récapitulatif</Link>,
                    key: 'models-recap'
                },
                {
                    item: <>Paramètres</>,
                    key: 'models-params',
                    children: [
                        {
                            item: <Link to="/models/parameters/device-types">Device types</Link>,
                            key: 'device-types'
                        },
                        {
                            item: <Link to="/models/parameters/device-ranges">Device ranges</Link>,
                            key: 'device-range'
                        },
                        {
                            item: <Link to="/models/parameters/device-groups">Device groups</Link>,
                            key: 'device-groups'
                        },
                        {
                            item: <Link to="/models/parameters/protocols">Protocol</Link>,
                            key: 'protocols'
                        },
                        {
                            item: (
                                <Link to="/models/parameters/device-manufacturers">
                                    Device manufacturers
                                </Link>
                            ),
                            key: 'device-manufacturers'
                        }
                    ]
                }
            ]
        },
        authService.canAccess('OPTIMISATION_MENU') && {
            item: (
                <>
                    <AutoFixHigh fontSize="small" /> <span>Optimisation</span>
                </>
            ),
            label: 'Optimisation',
            key: 'optimisation',
            children: [
                {
                    item: <Link to="/optimisation">Paramétrer</Link>,
                    key: 'optimisation-list'
                },
                {
                    item: <Link to="/optimisation/created">Suivi des chantiers</Link>,
                    key: 'optimisation-suivi'
                },
                {
                    item: <Link to="/optimisation/configuration">Configuration</Link>,
                    key: 'optimisation-config'
                },
                {
                    item: (
                        <Link to="/optimisation/contract-exclusion-list">
                            Gestion des exclusions de contrat
                        </Link>
                    ),
                    key: 'contract-exclusion-list'
                }
            ]
        },
        authService.canAccess('RACSUP_MENU') && {
            item: (
                <>
                    <PowerIcon fontSize="small" /> <span>RACSUP</span>
                </>
            ),
            key: 'racsup',
            children: [
                {
                    item: <>Primo raccordement</>,
                    key: 'primo',
                    children: [
                        {
                            item: <Link to="/racsup/create-link">Paramétrer</Link>,
                            key: 'create-link'
                        },
                        {
                            item: <Link to="/racsup/created-links">Suivi</Link>,
                            key: 'created-links'
                        }
                    ]
                },
                {
                    item: <>Re-raccordement</>,
                    key: 'rerac',
                    children: [
                        {
                            item: <Link to="/racsup/change-link">Paramétrer</Link>,
                            key: 'change-link'
                        },
                        {
                            item: <Link to="/racsup/changed-links">Suivi</Link>,
                            key: 'changed-links'
                        }
                    ]
                },
                {
                    item: <>Renforcement</>,
                    key: 'append',
                    children: [
                        {
                            item: <Link to="/racsup/append-link">Paramétrer</Link>,
                            key: 'append-link'
                        },
                        {
                            item: <Link to="/racsup/appended-links">Suivi</Link>,
                            key: 'appended-links'
                        }
                    ]
                },
                {
                    item: <>Nettoyage des répéteurs</>,
                    key: 'delete',
                    children: [
                        {
                            item: <Link to="/racsup/delete-link">Paramétrer</Link>,
                            key: 'delete-link'
                        },
                        {
                            item: <Link to="/racsup/deleted-links">Suivi</Link>,
                            key: 'deleted-links'
                        }
                    ]
                },
                {
                    item: <>Évictions</>,
                    key: 'evicted',
                    children: [
                        {
                            item: <Link to="/racsup/evicted-links">Suivi</Link>,
                            key: 'evicted-link'
                        },
                        {
                            item: (
                                <Link to="/racsup/modules-exclusion-list">
                                    Liste des exclusions de module
                                </Link>
                            ),
                            key: 'modules-exclusion-list'
                        }
                    ]
                },
                {
                    item: <Link to="/racsup/configuration">Configuration</Link>,
                    key: 'configuration'
                },
                {
                    item: (
                        <Link to="/racsup/contract-exclusion-list">
                            Gestion des exclusions de contrat
                        </Link>
                    ),
                    key: 'contract-exclusion-list'
                }
            ]
        },
        authService.canAccess('CONFIG_CR_MENU') && {
            item: (
                <>
                    <Link to="/configuration-cr">
                        <BuildIcon fontSize="small" /> <span>Configuration CR</span>
                    </Link>
                </>
            ),
            key: 'configuration-cr'
        },
        {
            item: (
                <>
                    <Link to="/statuses">
                        <MonitorHeartIcon fontSize="small" /> <span>Statuts Bases</span>
                    </Link>
                </>
            ),
            key: 'statuses'
        },
        authService.canAccess('ADMIN_MENU') && {
            item: (
                <>
                    <SettingsIcon fontSize="small" /> <span>Admin</span>
                </>
            ),
            key: 'admin',
            children: [
                {
                    item: <Link to="/admin/users">Utilisateurs</Link>,
                    key: 'users'
                },
                {
                    item: <Link to="/admin/groups">Groupes</Link>,
                    key: 'groups'
                },
                {
                    item: <Link to="/admin/sections">Sections</Link>,
                    key: 'sections'
                },
                {
                    item: <Link to="/admin/webhooks">Webhooks</Link>,
                    key: 'webhooks'
                },
                {
                    item: <Link to="/admin/customers">Clients</Link>,
                    key: 'customers'
                },
                {
                    item: <Link to="/admin/contracts">Contrats</Link>,
                    key: 'contracts'
                },
                authService.canAccess('OPERATOR_MENU') && {
                    item: <Link to="/admin/operators">Opérateurs</Link>,
                    key: 'operators'
                },
                {
                    item: <Link to="/admin/messages">Messages</Link>,
                    key: 'messages'
                },
                {
                    item: <Link to="/admin/logs">Logs</Link>,
                    key: 'logs'
                },
                authService.canAccess('TOKEN_MENU') && {
                    item: <Link to="/admin/tokens">Tokens</Link>,
                    key: 'tokens'
                }
            ]
        }
    ];

    const SidebarItems = ({ items }: any) => {
        return items.map((sidebarItem: any) => {
            if (!sidebarItem) return null;
            const hasChildren = sidebarItem.children && sidebarItem?.children?.length > 0;
            const hasActiveChild =
                location.pathname === sidebarItem.key ||
                sidebarItem.children?.some((child: any) => {
                    return location.pathname === child.key;
                });
            const sidebarIsExpanded = expanded === sidebarItem.key;
            return (
                <Accordion
                    key={`${sidebarItem.key}`}
                    disableGutters
                    expanded={sidebarIsExpanded}
                    onChange={hasChildren ? handleChange(sidebarItem.key) : undefined}
                    onMouseEnter={() => {
                        handleHoverOn(`${sidebarItem.key}`);
                    }}
                    onMouseLeave={() => {
                        handleHoverOff();
                    }}
                    className={`${elementHovered === sidebarItem.key && 'hover'}`}>
                    <AccordionSummary
                        className={hasActiveChild && sidebarIsExpanded ? 'active' : ''}
                        expandIcon={hasChildren && <KeyboardArrowRightIcon />}>
                        {sidebarItem.item}
                    </AccordionSummary>
                    {hasChildren && (
                        <AccordionDetails>
                            {sidebarItem.children.map((child: any) => {
                                if (!child) return null;
                                const childIsActive =
                                    sidebarIsExpanded && location.pathname === child.key;

                                return (
                                    <Box
                                        key={`${sidebarItem.key}/${child.key}`}
                                        sx={{ ml: 3, mb: 0.5 }}
                                        className={childIsActive ? 'active' : ''}>
                                        {child.item}
                                        {child.children && (
                                            <Box sx={{ ml: 3 }}>
                                                {child.children.map((subChild: any) => {
                                                    if (!subChild) return null;
                                                    return (
                                                        <Box key={`${child.key}/${subChild.key}`}>
                                                            {subChild.item}
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </AccordionDetails>
                    )}
                </Accordion>
            );
        });
    };

    return (
        <div className={'sidebar ' + apiVersion?.environment} {...rest}>
            {apiVersion?.dry_run && <div className="dry-run">Dry Run</div>}
            <div className="logo">
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Box display="flex">
                            <Link to="/" id="logo">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/logo-transparent-horizontal-small-white.png'
                                    }
                                    alt="B-Link"
                                />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="welcome">
                        <Box>
                            {authService.user?.avatar ? (
                                <div
                                    className="avatar"
                                    style={{
                                        backgroundImage: 'url("' + authService.user.avatar + '"'
                                    }}
                                />
                            ) : (
                                <AccountCircle fontSize="small" />
                            )}
                        </Box>
                        <Box sx={{ ml: 3 }} textAlign={'left'}>
                            Bienvenue
                            <br />
                            {authService?.user?.name || ''}
                        </Box>
                    </Grid>
                </Grid>
            </div>

            <IconButton id="burger" onClick={() => toggleBurger()}>
                <Menu fontSize="small" />
            </IconButton>

            <div className="sidebar-wrapper">
                <div className="sidebar-scroll">
                    <SidebarItems items={sidebarItems} />
                </div>
            </div>

            <span className="logout">
                <IconButton id={'logout'} onClick={authService.logout}>
                    <PowerSettingsNew fontSize="small" />
                </IconButton>
            </span>

            <span className="version">
                <Box
                    onClick={() => {
                        setShowUiVersion(!showUiVersion);
                    }}>
                    <span title="Version">Version: {apiVersion?.version}</span>{' '}
                    <span
                        title="UI version"
                        style={{ visibility: showUiVersion ? 'visible' : 'hidden' }}>
                        {' '}
                        / UI: {appVersion}
                    </span>
                </Box>

                <span title="Codecs version">Codecs: {codec}</span>
            </span>
        </div>
    );
};

export default Sidebar;
