import React, { useEffect, useState } from 'react';
import { SearchField } from '@applications-terrains/birdz-react-library';
import PurposesList from './PurposesList';
import {
    commonFilters,
    searchFields,
    getAvailableSearchFilters,
    purposeFilters
} from '../search.config';
import { useTranslation } from 'react-i18next';
import { useSearchCampaignsAndPurposesContext } from '../SearchCampaignsAndPurposesContext';
import BSearchForm from '../../../Tools/Search/BSearchForm';

const Purposes = () => {
    const [purposesSearchFields, setPurposesSearchFields] = useState<SearchField[]>([]);
    const { t } = useTranslation();

    const { setPurposesFiltersString, purposesValues, setPurposesValues } =
        useSearchCampaignsAndPurposesContext();

    const formatFilterString = (str: string) => str.replace('&campaignId=', '&campaign=');

    useEffect(() => {
        if (purposesSearchFields.length === 0) {
            let purposesSearchFields = [...searchFields];

            // Rename common fields
            purposesSearchFields = purposesSearchFields.map((searchField) => {
                if (
                    commonFilters.includes(searchField.name) &&
                    purposeFilters.indexOf(searchField.name) === -1
                ) {
                    searchField.name = 'campaign__' + searchField.name;
                }
                return searchField;
            });

            // Add specific purposes search fields
            const availableSearchFilters = getAvailableSearchFilters(t);
            purposesSearchFields.unshift(
                availableSearchFilters['purposeState'],
                availableSearchFilters['nackCause']
            );

            const purposeCreatedByPosition = purposesSearchFields
                .map((searchField) => searchField.name)
                .indexOf('campaign__created_by__in');
            purposesSearchFields.splice(
                purposeCreatedByPosition + 1,
                0,
                availableSearchFilters['purposeDevice']
            );

            // State is common to two tabs but it's not the same filter
            const deviceAddressFilterPosition = purposesSearchFields
                .map((searchField) => searchField.name)
                .indexOf('device__address');
            let campaignStateFilter = Object.assign({}, availableSearchFilters['campaignState'], {
                name: 'campaign__state__in'
            });
            purposesSearchFields.splice(deviceAddressFilterPosition + 1, 0, campaignStateFilter);
            purposesSearchFields.splice(
                deviceAddressFilterPosition + 2,
                0,
                availableSearchFilters['txreject__reject_id__in']
            );
            purposesSearchFields.push(availableSearchFilters['purposeId']);
            setPurposesSearchFields(purposesSearchFields);
        }
    }, [purposesSearchFields, t]);

    return (
        <>
            <BSearchForm
                fields={purposesSearchFields}
                values={purposesValues}
                setSearchString={(filterString) =>
                    setPurposesFiltersString(formatFilterString(filterString))
                }
                setSearchValues={setPurposesValues}
            />
            <PurposesList />
        </>
    );
};

export default Purposes;
