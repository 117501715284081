import {
    ListField,
    ListPage,
    SearchField,
} from '@applications-terrains/birdz-react-library';
import { Box, CircularProgress, Skeleton } from '@mui/material';
import { useIsLoadingContext } from '../../contexts/IsLoadingContext';
import { useEffect, useState } from 'react';
import axios from 'axios';

type ListPageWrapperProps = {
    fields: ListField[];
    searchFields?: SearchField[];
    refresh?: boolean;
    [key: string]: any;
};

const ListPageWrapper = ({
    fields,
    searchFields,
    endpoint,
    refresh,
    ...rest
}: ListPageWrapperProps) => {
    const { isLoading } = useIsLoadingContext();
    const [internalLoading, setInternalLoading] = useState(true);
    const [dataLength, setDataLength] = useState<number | null>(null);

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            (config) => {
                return { ...config, component: 'ListPageWrapper' };
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        //Cleanup on unmount
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
        };
    }, []);

    const display =
        rest?.loadedData && rest?.loadedData?.length > 0
            ? 'block'
            : internalLoading || isLoading || refresh
              ? 'none'
              : 'block';

    return (
        <div>
            {display === 'none' && (
                <div className="w-100">
                    <Skeleton
                        variant="rounded"
                        width={120}
                        height={30}
                        animation="wave"
                        sx={{
                            bgcolor: 'rgb(249, 249, 249)',
                            margin: '19px 0 13px 0'
                        }}
                    />
                    <Box
                        id="fake-list-page"
                        style={{
                            borderRadius: '4px',
                            overflow: 'hidden',
                            boxShadow:
                                '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                        }}>
                        <Box
                            sx={{
                                bgcolor: 'rgb(222, 226, 230)'
                            }}>
                            <Box
                                sx={{ p: 2.1 }}
                                style={{
                                    borderBottom: '1px solid rgb(222, 226, 230)'
                                }}>
                                <CircularProgress size={20} sx={{ mr: 1 }} /> Chargement en cours...{' '}
                            </Box>
                        </Box>
                        {Array(dataLength || 25)
                            .fill(undefined)
                            .map((_, index) => (
                                <Skeleton
                                    key={'fake-listPage' + index}
                                    variant="rectangular"
                                    width={'100%'}
                                    height={49}
                                    animation="wave"
                                    sx={{
                                        bgcolor: index % 2 === 0 ? 'rgb(249, 249, 249)' : 'white',
                                        borderBottom: '1px solid rgb(222, 226, 230)'
                                    }}
                                />
                            ))}
                    </Box>
                </div>
            )}
            <div
                id="listPageContainer"
                className="w-100"
                style={{
                    display
                }}>
                {refresh !== true && (
                    <ListPage
                        endpoint={endpoint}
                        fields={fields}
                        searchFields={searchFields}
                        {...rest}
                        onLoadItems={(data) => {
                            setDataLength(data.length);
                            if (typeof rest.onLoadItems === 'function') {
                                rest.onLoadItems(data);
                            }
                            setInternalLoading(false);
                        }}
                        onError={() => setInternalLoading(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default ListPageWrapper;
