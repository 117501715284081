import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useFetchContext } from '../contexts/FetchContext';

export type FetchDataObject = {
    key: string;
    endpoint: string;
    enableFetch: boolean;
    resultsField?: string;
};

const useFetchData = ({ key, endpoint, enableFetch, resultsField }: FetchDataObject) => {
    const { dataRefs, setDataRefs } = useFetchContext();

    return useQuery({
        queryKey: [key, endpoint],
        enabled: enableFetch, // Utilise le paramètre enableFetch pour activer/désactiver le fetch
        queryFn: async () => {
            const { data } = await axios.get(endpoint);
            const dataObj =
                resultsField && resultsField in data
                    ? { endpoint, count: data[resultsField].length, results: data[resultsField] }
                    : data.results
                      ? { endpoint, ...data }
                      : { endpoint, count: data.length, results: data };

            // Met à jour le contexte des références
            setDataRefs((currentRefs: any) => ({
                ...currentRefs,
                [key]: dataObj
            }));

            return dataObj;
        },
        initialData: enableFetch ? undefined : dataRefs[key] // Fournit une donnée initiale si `enableFetch` est faux
    });
};

export default useFetchData;
