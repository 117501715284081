import dataService from '../Common/Services/dataService';
import {
    useDialog,
    BirdzDialog,
    Item,
    FormField,
    AppPaper,
    StyledTitle,
    useNotif,
    BirdzNotif,
    SearchField
} from '@applications-terrains/birdz-react-library';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import * as Yup from 'yup';
import axios from 'axios';
import { DeviceGroup, DeviceType } from '../types';
import { authService } from '../..';
import { Button, Grid, IconButton } from '@mui/material';
import { AddCircle, ContentCopy, Delete, Edit } from '@mui/icons-material';
import ListPageWrapper from '../Tools/ListPageWrapper';

interface Props {
    action: 'list' | 'add' | 'edit' | 'duplicate';
}

type DeviceRangeItem = {
    device: number;
    device_name: string;
    device_technical_code: string;
    device_bconnect_technical_code: string;
    device_bconnect_technical_code_key: null | string;
    device_encryption_keys: boolean;
    group: number;
    group_name: string;
    group_technical_code: string;
    manufacturer: number;
    manufacturer_name: string;
    manufacturer_technical_code: string;
    designation: string;
    vertical: string;
    protocols: string[];
    frequencies: string[];
    builder: string;
    sub_type_start: string;
    sub_type_end: string;
    type: string;
    millesime_start: string;
    millesime_end: string;
    serial_start: string;
    serial_end: string;
    firmware_version: string;
    zone_info: null | string;
    in_production: boolean;
    is_active: boolean;
    id: number;
    created_at: number;
    updated_at: number;
};

export const DeviceRanges = ({ action }: Props) => {
    const [verticals, setVerticals] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/verticals/');
                const data = response.data;
                const newVerticals = data.verticals.map((v: string) => {
                    return { value: v, label: v };
                });
                setVerticals(newVerticals);
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log('Request was canceled:', err.message);
                } else {
                    console.error('Error fetching data:', err);
                }
            }
        };

        fetchData();
    }, []);

    const endpoint = '/api/device-ranges/';
    const formFields: FormField[] = [
        {
            name: 'device',
            type: 'select',
            label: 'Device',
            options: {
                values: dataService.getSelectData('deviceTypes', {}, { label: 'name' })
            },
            validation: Yup.number()
                .typeError('Vous devez choisir un device')
                .required('Vous devez choisir un device')
        },
        {
            name: 'designation',
            label: 'Désignation',
            type: 'text'
        },
        {
            name: 'group',
            type: 'select',
            label: 'Groupe',
            options: {
                values: dataService.getSelectData(
                    'deviceGroups',
                    { is_active: true },
                    { label: 'name' }
                )
            },
            validation: Yup.number()
                .typeError('Vous devez choisir un groupe')
                .required('Vous devez choisir un groupe')
        },
        {
            name: 'builder',
            label: 'Code fabricant',
            type: 'number',
            size: 6,
            labelSize: 4,
            fieldSize: 8,
            validation: Yup.number()
                .typeError('Le code fabriquant doit être numérique')
                .required('Vous devez choisir un code fabricant')
        },
        {
            name: 'manufacturer',
            label: 'Fabricant',
            type: 'autocomplete',
            size: 6,
            options: {
                source: '/api/lists/device-manufacturers/?ordering=name&',
                searchIsMulti: false
            },
            validation: Yup.object()
                .typeError('Vous devez choisir un fabricant')
                .required('Vous devez choisir un fabricant')
        },
        {
            name: 'sub_type_start',
            label: 'Code sous type de: ',
            type: 'text',
            size: 6,
            labelSize: 4,
            fieldSize: 8,
            validation: Yup.string()
                .max(2, 'Le sous-type (début) ne doit pas excèder 2 caractères')
                .trim()
                .matches(
                    /^([0-9A-F]{0,2})$/i,
                    'Le sous-type (début) doit être au format hexadécimal'
                )
                .required('Vous devez choisir un sous-type (début)')
        },
        {
            name: 'sub_type_end',
            label: '>= et <=',
            type: 'text',
            size: 6,
            validation: Yup.string()
                .max(2, 'Le sous-type (fin) ne doit pas excèder 2 caractères')
                .trim()
                .matches(/^([0-9A-F]{0,2})$/i, 'Le sous-type (fin) doit être au format hexadécimal')
                .required('Vous devez choisir un sous-type (fin)')
        },
        {
            name: 'type',
            label: 'Code type',
            type: 'text',
            validation: Yup.string()
                .max(2, 'Le code type ne doit pas excèder 2 caractères')
                .trim()
                .matches(/^([0-9A-F]{0,2})$/i, 'Le code type doit être au format hexadécimal')
                .required('Vous devez choisir un code type')
        },
        {
            name: 'millesime_start',
            label: 'Code millesime: ',
            type: 'number',
            size: 6,
            labelSize: 4,
            fieldSize: 8,
            validation: Yup.string()
                .matches(/^[0-9]+$/, 'Le code millésime (début) doit être numérique')
                .min(4, 'Le code millésime (début) doit faire 4 caractères')
                .max(4, 'Le code millésime (début) doit faire 4 caractères')
                .required('Vous devez choisir le code millésime (début)')
        },
        {
            name: 'millesime_end',
            label: '>= et <=',
            type: 'number',
            size: 6,
            validation: Yup.string()
                .matches(/^[0-9]+$/, 'Le code millésime (fin) doit être numérique')
                .min(4, 'Le code millésime (fin) doit faire 4 caractères')
                .max(4, 'Le code millésime (fin) doit faire 4 caractères')
                .required('Vous devez choisir le code millésime (fin)')
        },
        {
            name: 'serial_start',
            label: 'Numéro de série: ',
            type: 'text',
            size: 6,
            labelSize: 4,
            fieldSize: 8,
            validation: Yup.string()
                .max(4, 'Le numéro de série (début) ne doit pas excèder 4 caractères')
                .required('Vous devez choisir le numéro de série (début)')
        },
        {
            name: 'serial_end',
            label: '>= et <=',
            type: 'text',
            size: 6,
            validation: Yup.string()
                .max(4, 'Le numéro de série (fin) ne doit pas excèder 4 caractères')
                .required('Vous devez choisir le numéro de série (fin)')
        },
        {
            name: 'firmware_version',
            label: 'Firmware version',
            type: 'text'
        },
        {
            name: 'zone_info',
            label: 'Zone info',
            type: 'text'
        },
        {
            name: 'vertical',
            label: 'Verticale',
            type: 'creatable',
            options: {
                values: verticals
            }
        },
        {
            name: 'protocols',
            label: 'Protocoles',
            type: 'select-multiple',
            options: {
                values: [
                    { value: 'WB', label: 'WB' },
                    { value: 'NET', label: 'NET' },
                    { value: 'LR', label: 'LR' },
                    { value: 'LoRaWAN', label: 'LoRaWAN' },
                    { value: 'Sigfox', label: 'Sigfox' },
                    { value: 'Nb-IOT', label: 'Nb-IOT' },
                    { value: 'LTE-M', label: 'LTE-M' },
                    { value: 'GSM', label: 'GSM' },
                    { value: 'wM-Bus', label: 'wM-Bus' }
                ]
            },
            validation: Yup.array()
                .of(Yup.string())
                .min(1, 'Vous devez choisir au moins un protocole')
        },
        {
            name: 'frequencies',
            label: 'Fréquences',
            type: 'select-multiple',
            options: {
                values: [
                    { value: '868MHz', label: '868MHz' },
                    { value: '433MHz', label: '433MHz' },
                    { value: '495MHz', label: '495MHz' }
                ]
            },
            validation: Yup.array()
                .of(Yup.string())
                .min(1, 'Vous devez choisir au moins une fréquence')
        },
        {
            name: 'in_production',
            label: 'En production',
            type: 'checkbox'
        },
        {
            name: 'is_active',
            type: 'radio',
            label: 'Etat',
            options: {
                values: [
                    { value: true, label: 'Actif' },
                    { value: false, label: 'Inactif' }
                ]
            },
            defaultValue: true,
            permissions: ['IS_ACTIVE']
        },
        {
            name: 'updated_at',
            type: 'readonly',
            label: 'Dernière modification',
            transform: (value: number) => {
                const date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
                return date || '';
            }
        }
    ];
    const listFields = [
        {
            name: 'device',
            label: 'Device',
            orderable: true,
            transform: (deviceTypeId: number) => {
                const dataServiceDeviceTypes = dataService.getData('deviceTypes') as DeviceType[];
                const deviceType = dataServiceDeviceTypes.find(
                    (dataServiceDeviceType) => dataServiceDeviceType.id === deviceTypeId
                );
                return deviceType ? deviceType.name : '';
            }
        },
        {
            name: 'designation',
            label: 'Désignation',
            orderable: true
        },
        {
            name: 'group',
            label: 'Groupe',
            orderable: true,
            transform: (deviceGroupId: number) => {
                const dataServiceDeviceGroups = dataService.getData(
                    'deviceGroups'
                ) as DeviceGroup[];
                const deviceGroup = dataServiceDeviceGroups.find(
                    (dataServiceDeviceGroup) => dataServiceDeviceGroup.id === deviceGroupId
                );
                return deviceGroup?.name;
            }
        },
        {
            name: 'builder',
            label: 'Code fabricant',
            orderable: true
        },
        {
            name: 'sub_type_start',
            label: 'Sous-type',
            orderable: true,
            transform: (_: any, field: any) => {
                return `${field?.sub_type_start || '-'} >= et <= ${field?.sub_type_end || '-'}`;
            }
        },
        {
            name: 'type',
            label: 'Code type',
            orderable: true
        },
        {
            name: 'millesime_start',
            label: 'Millésime',
            orderable: true,
            transform: (osef: any, field: any) => {
                return `${field?.millesime_start || '-'} >= et <= ${field?.millesime_end || '-'}`;
            }
        },
        {
            name: 'serial_start',
            label: 'Numéro de série',
            orderable: true,
            transform: (osef: any, field: any) => {
                return `${field?.serial_start || '-'} >= et <= ${field?.serial_end || '-'}`;
            }
        },
        {
            name: 'firmware_version',
            label: 'Version firmware',
            orderable: true
        },
        {
            name: 'in_production',
            label: 'En production',
            type: 'boolean'
        }
    ];

    const getSearchFieldOptions = (field: string) =>
        [...new Set(items.map((item) => item[field]))]
            .filter((el) => el)
            .map((fieldValue) => ({
                label: fieldValue,
                value: fieldValue
            }))
            .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

    const searchFields: SearchField[] = [
        {
            name: 'designation__in',
            label: 'Désignation',
            type: 'select-multiple',
            options: {
                values: getSearchFieldOptions('designation')
            }
        },
        {
            name: 'type__in',
            label: 'Code type',
            type: 'select-multiple',
            options: {
                values: getSearchFieldOptions('type')
            }
        },
        {
            name: 'sub_type_start__in',
            label: 'Sous-type entre',
            type: 'select-multiple',
            options: {
                values: getSearchFieldOptions('sub_type_start')
            }
        },
        {
            name: 'sub_type_end__in',
            label: 'et',
            type: 'select-multiple',
            options: {
                values: getSearchFieldOptions('sub_type_end')
            }
        }
    ];
    const actions = [
        {
            name: 'edit',
            render: (deviceRange: DeviceRangeItem) => {
                return (
                    <IconButton
                        id={'deviceRange-edit-button'}
                        component={Link}
                        to={`/models/parameters/device-ranges/edit/${deviceRange.id}`}>
                        <Edit fontSize="small" />
                    </IconButton>
                );
            }
        },
        {
            name: 'copy',
            render: (deviceRange: DeviceRangeItem) => {
                return (
                    <IconButton
                        id={'deviceRange-copy-button'}
                        component={Link}
                        to={`/models/parameters/device-ranges/duplicate/${deviceRange.id}`}
                        title="Dupliquer">
                        <ContentCopy fontSize="small" />
                    </IconButton>
                );
            }
        },
        {
            name: 'delete-devicerange',
            render: (deviceRange: DeviceRangeItem) => {
                return (
                    <IconButton
                        id={'deviceRange-delete-button'}
                        onClick={() => {
                            confirmDialog({
                                title: 'Supprimer le device range',
                                content: 'Êtes-vous sûr de vouloir supprimer ce device range?',
                                onValidate: () => {
                                    axios.delete(`${endpoint}${deviceRange.id}/`).then(
                                        () => {
                                            notif({
                                                content:
                                                    'Le device range a été supprimé avec succès',
                                                type: 'success'
                                            });
                                            document.location.reload();
                                        },
                                        () => {
                                            notif({
                                                content:
                                                    'Une erreur est survenue lors de la suppression',
                                                type: 'error'
                                            });
                                        }
                                    );
                                    closeDialog();
                                },
                                onCancel: () => {
                                    closeDialog();
                                }
                            });
                        }}>
                        <Delete fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    const transformItemOnLoad = (item: any) => {
        if (action === 'duplicate') {
            delete item.id;
            item.designation += '_COPY';
        }

        if (item.manufacturer) {
            item.manufacturer = {
                value: item.manufacturer,
                label: item.manufacturer_name
            };
        }
        return item;
    };

    const transformItemOnSave = (item: any) => {
        if (item.manufacturer && item.manufacturer?.value) {
            item.manufacturer = item.manufacturer?.value;
        }
        return item;
    };

    return (
        <AppPaper>
            {action === 'list' && (
                <>
                    <Grid justifyContent="space-between" container>
                        <Grid item>
                            <StyledTitle>Liste des device ranges</StyledTitle>
                        </Grid>
                        {authService.canAccess('WRITE_PARAMETERS') && (
                            <Grid item>
                                <Link to="/models/parameters/device-ranges/add">
                                    <Button
                                        id={'deviceRange-add-button'}
                                        variant="contained"
                                        sx={{ mt: 3 }}>
                                        <AddCircle sx={{ mr: 1 }} /> Ajouter un device range
                                    </Button>
                                </Link>
                            </Grid>
                        )}
                    </Grid>

                    <ListPageWrapper
                        endpoint={endpoint}
                        fields={listFields}
                        searchFields={searchFields}
                        actions={authService.canAccess('WRITE_PARAMETERS') ? actions : []}
                        paginationOptions={{ pageSize: 1000 }}
                        defaultOrder={['device__name']}
                        onLoadItems={(data: any) => items.length === 0 && setItems(data)}
                    />
                    <BirdzDialog options={dialogOptions} />
                </>
            )}
            {(action === 'add' || action === 'duplicate') && (
                <Item
                    action="add"
                    endpoint={endpoint}
                    fields={formFields}
                    name={
                        action === 'add' ? 'Ajouter un device range' : 'Dupliquer le device range'
                    }
                    transformItemOnLoad={transformItemOnLoad}
                    transformItemOnSave={transformItemOnSave}
                    onSave={() => {
                        setTimeout(() => {
                            navigate('/models/parameters/device-ranges');
                        }, 2000);
                    }}
                />
            )}
            {action === 'edit' && (
                <Item
                    action="edit"
                    endpoint={endpoint}
                    fields={formFields}
                    name="Modifier le device range"
                    transformItemOnLoad={transformItemOnLoad}
                    transformItemOnSave={transformItemOnSave}
                />
            )}
            <BirdzNotif options={notifOptions} />
        </AppPaper>
    );
};

export default DeviceRanges;
