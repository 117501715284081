import { useState } from 'react';
import { Autocomplete, Box, CircularProgress } from '@mui/material';
import CustomTextField from './CustomTextField';
import { FieldType } from '../../types';
import { useFetchContext } from '../../../../../contexts/FetchContext';
import useFetchData from '../../../../../hooks/useFetchData';

const AutoFetchSelect = ({
    field,
    value,
    setFieldValue,
    endpoint
}: FieldType & { endpoint: string }) => {
    const [search, setSearch] = useState('');
    const [focused, setFocused] = useState(false);
    const { dataRefs } = useFetchContext();
    const dataRefFieldName = field.name.replace('__in', '').replace('campaign__', '');

    const searchableFields = [
        'contracts__in',
        'cities__in',
        'campaign__cities__in',
        'campaign__contracts__in'
    ];
    const targetKey =
        field.name === 'cities__in' || field.name === 'campaign__cities__in' ? '?name=' : 'label=';

    const enableFetch =
        (!searchableFields.includes(field.name) && !(dataRefFieldName in dataRefs)) ||
        (searchableFields.includes(field.name) && search.length > 2);

    const fetchEndpoint = enableFetch
        ? `${endpoint}${search.length > 2 ? targetKey : ''}${search}`
        : endpoint;

    const getFormattedFetchEndpoint = (endpoint: string) =>
        endpoint[endpoint.length - 1] === '?' || endpoint[endpoint.length - 1] === '&'
            ? endpoint.slice(0, -1)
            : endpoint;

    const { isLoading } = useFetchData({
        key: dataRefFieldName,
        endpoint: getFormattedFetchEndpoint(fetchEndpoint),
        resultsField: field.options?.resultsField,
        enableFetch
    });

    const options = dataRefs[dataRefFieldName]?.results || [];

    return (
        <Autocomplete
            size="small"
            className="w-100"
            multiple={field.name.includes('__in')}
            value={value || []}
            onChange={(_, newValue) => setFieldValue(field.name, newValue)}
            options={options.map((el: { [key: string]: string | number } | string) => ({
                value: typeof el === 'string' ? el : el.id || el.city_id || el.code,
                label: typeof el === 'string' ? el : el.name || el.label || el.code
            }))}
            getOptionLabel={(option) => option.label}
            groupBy={(option) => option.group || ''}
            isOptionEqualToValue={(option: any, value: any) => {
                return option.value === value.value;
            }}
            onBlur={() => {
                setFocused(false);
            }}
            onFocus={() => {
                setFocused(true);
            }}
            renderInput={(params) => {
                return (
                    <CustomTextField
                        field={field}
                        onSearch={(search) =>
                            searchableFields.includes(field.name) ? setSearch(search) : null
                        }
                        placeholder={
                            searchableFields.includes(field.name) ? 'minimum 3 caractères' : ''
                        }
                        noOptions={
                            focused && search.length > 0 && options.length === 0 && !isLoading
                        }
                        {...params}
                    />
                );
            }}
            loading={isLoading}
            loadingText={
                <Box className="d-flex align-items-center">
                    recherche en cours... <CircularProgress size={16} sx={{ ml: 1 }} />
                </Box>
            }
            disableCloseOnSelect
            freeSolo
            forcePopupIcon
        />
    );
};

export default AutoFetchSelect;
