import React from 'react';
import CampaignsList from '../Lists/Campaigns/CampaignsList';
import { BirdzTitle } from '@applications-terrains/birdz-react-library';
import { SearchCampaignsAndPurposesContextProvider } from '../Lists/SearchCampaignsAndPurposesContext';
import PagePaper from '../../Tools/Pages/PagePaper';

export const ArchivedCampaigns = () => {
    return (
        <PagePaper title={<BirdzTitle className='m-0'>Campagnes archivées</BirdzTitle>}>
            <SearchCampaignsAndPurposesContextProvider>
                <CampaignsList archived={true} />
            </SearchCampaignsAndPurposesContextProvider>
        </PagePaper>
    );
};
export default ArchivedCampaigns;
