import React, { useState } from 'react';
import './LinkSearchContracts.scss';
import { Box, Button, Grid } from '@mui/material';
import { AppPaper, AutocompleteField } from '@applications-terrains/birdz-react-library';

export const LinkSearchContracts = ({ onFilter, endpoint, resultsField, formatResponse }: any) => {
    const [value, setValue] = useState<any>();
    return (
        <AppPaper>
            <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={3}>
                    Contrats
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ width: 300, mr: 2 }}>
                            <AutocompleteField
                                field={{
                                    name: 'contract',
                                    label: 'Contrat',
                                    options: {
                                        identifier: 'contract',
                                        source: endpoint,
                                        label: 'contract',
                                        searchIsMulti: true,
                                        resultsField: resultsField,
                                        formatResponse:
                                            typeof formatResponse === 'function'
                                                ? formatResponse
                                                : (response: any) => {
                                                      return {
                                                          label: `${response.contract} - ${response.contract_label}`,
                                                          value: response.contract
                                                      };
                                                  }
                                    }
                                }}
                                onSelect={(contract: any[]) => {
                                    setValue(contract);
                                }}
                                value={value || null}
                            />
                        </Box>
                        <Button
                            id={'linkSearch-filter-button'}
                            variant="contained"
                            disabled={!value}
                            onClick={() => {
                                onFilter(value);
                            }}
                            sx={{ mr: 2, height: '38px' }}>
                            Filtrer
                        </Button>
                        <Button
                            id={'linkSearch-cancel-button'}
                            variant="outlined"
                            disabled={!value}
                            onClick={() => {
                                onFilter([]);
                                setValue(undefined);
                            }}
                            sx={{ height: '38px' }}>
                            Annuler
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </AppPaper>
    );
};

export default LinkSearchContracts;
