import axios from 'axios';
import dataService from '../Common/Services/dataService';
import EncoderService from '../Common/Services/encoderService';
import _ from 'lodash';
import { Device } from '../types';
import { showFriendlyErrorsHTML } from '@applications-terrains/birdz-react-library';
import { NotifObjType } from '../Tools/NotifWithCountdown';

class CampaignService {
    private encoderService: any = new EncoderService();

    public async save(campaign: any): Promise<any> {
        // Si la campagne contient des "multi_protocols_values", on la splitte pour envoyer autant de campagnes qu'il y a d'operators dans multi_protocols_values
        if (
            campaign?.multi_protocols_values &&
            Object.keys(campaign?.multi_protocols_values).length
        ) {
            //check if all frame templates are setted
            let error = '';
            Object.keys(campaign.multi_protocols_values).forEach((protocolId: string) => {
                const protocolValue = campaign.multi_protocols_values[protocolId];
                if (!protocolValue?.frame_template?.value) {
                    error =
                        'Vous devez renseigner tous les modèles pour les différents protocoles de la campagne';
                }
            });

            if (
                Object.keys(campaign.multi_protocols_values).length !==
                _.uniq(campaign.devices.map((device: Device) => device.protocol)).length
            ) {
                error =
                    'Vous devez renseigner tous les modèles pour les différents protocoles de la campagne';
            }
            if (error) {
                return Promise.reject(error);
            }

            const savePromises: Promise<any>[] = [];
            const deviceOperators: unknown[] = this.getDevicesOperators(campaign.devices);

            if (deviceOperators.length > 0) {
                deviceOperators.forEach((operatorId) => {
                    const _campaign = Object.assign({}, campaign);
                    const operatorDevices = _campaign.devices.filter(
                        (device: Device) => device.operator === operatorId
                    );
                    const operatorProtocolId =
                        operatorDevices && operatorDevices.length
                            ? operatorDevices[0]?.protocol
                            : null;
                    if (operatorDevices && operatorProtocolId) {
                        const protocolValues = campaign?.multi_protocols_values[operatorProtocolId];
                        _campaign.frame_template = protocolValues?.frame_template?.value;
                        _campaign.devices = operatorDevices;
                        _campaign.operator = operatorId;
                        _campaign.scheduled_date = protocolValues?.schedule?.scheduled_date;
                        _campaign.sending_schedule = protocolValues?.schedule?.sending_schedule;
                        delete _campaign.multi_protocols_values;
                        savePromises.push(this.save(_campaign));
                    }
                });
            }

            return Promise.all(savePromises);
        } else if (!campaign.operator) {
            const deviceOperators = this.getDevicesOperators(campaign.devices);
            if (deviceOperators.length > 1) {
                const savePromises: Promise<any>[] = [];
                deviceOperators.forEach((operatorId) => {
                    const _campaign = Object.assign({}, campaign);
                    const operatorDevices = _campaign.devices.filter(
                        (device: Device) => device.operator === operatorId
                    );
                    const operatorProtocolId =
                        operatorDevices && operatorDevices.length
                            ? operatorDevices[0]?.protocol
                            : null;
                    if (operatorDevices && operatorProtocolId) {
                        _campaign.devices = operatorDevices;
                        _campaign.operator = operatorId;
                        savePromises.push(this.save(_campaign));
                    }
                });

                return Promise.all(savePromises);
            }

            // we take the first device operator (all device has the same operator in this case)
            const operator =
                campaign.devices && campaign.devices.length > 0
                    ? campaign.devices[0]?.operator
                    : null;
            if (operator) {
                campaign.operator = operator;
            }
        }

        return new Promise<void>((resolve, reject) => {
            if (Object.keys(campaign).length === 0) {
                reject('Campaign object cannot be empty');
            } else {
                campaign.sending_schedule = campaign.sending_schedule || 'now';

                if (
                    !campaign.frame_template &&
                    campaign.codecs_path &&
                    Object.keys(campaign.codecs_path).length
                ) {
                    if (!campaign.header_config) {
                        const headerConfigField = this.encoderService.getHeaderConfigField();
                        if (headerConfigField) {
                            if (
                                campaign.payload_clear &&
                                campaign.payload_clear[headerConfigField]
                            ) {
                                campaign.header_config = {
                                    ...campaign.payload_clear[headerConfigField]
                                };
                                delete campaign.payload_clear[headerConfigField];
                            }
                        }
                    }
                }

                if (campaign.port === 0) {
                    delete campaign.port;
                }

                if (!campaign.id) {
                    axios.post('/api/campaigns/', campaign).then(
                        () => {
                            resolve(campaign);
                        },
                        (error: any) => {
                            reject(error);
                        }
                    );
                }
            }
        });
    }

    successSavingNotification = (): NotifObjType => {
        return {
            content: 'Votre intention a bien été sauvegardée',
            type: 'success'
        };
    };

    errorSavingNotification = (error: any) => {
        if (typeof error === 'string') {
            return {
                content: error,
                type: 'error'
            };
        }
        const errors = error?.response?.data || [];
        return {
            content: showFriendlyErrorsHTML(
                errors,
                "Une erreur est survenue lors de la création de l'intention"
            ),
            type: 'error'
        };
    };

    getFrameTemplate(campaignFrameTemplate: number) {
        return new Promise((resolve, reject) => {
            axios.get('/api/frame-templates/' + campaignFrameTemplate + '/').then(
                (response: any) => {
                    resolve(response.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    getOperator(campaignOperator: number) {
        return new Promise((resolve, reject) => {
            axios.get('/api/operators/' + campaignOperator + '/').then(
                (response: any) => {
                    resolve(response.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    checkDeviceTypes = (devices: Device[], frameTemplate: number) => {
        return campaignService.getFrameTemplate(frameTemplate).then((frameTemplateData: any) => {
            const frameTemplateDeviceTypes = frameTemplateData.device_types;
            const errors: any[] = [];
            devices.forEach((deviceData: any) => {
                if (frameTemplateDeviceTypes.indexOf(deviceData.device_type) === -1) {
                    errors.push(deviceData);
                }
            });

            if (errors.length) {
                const templateDeviceTypes: string[] = [];
                frameTemplateDeviceTypes.forEach((frameTemplateDeviceType: any) => {
                    const foundDeviceType = dataService
                        .getData('deviceTypes')
                        .find((deviceType: any) => deviceType.id === frameTemplateDeviceType);
                    if (foundDeviceType) {
                        templateDeviceTypes.push(foundDeviceType.name);
                    }
                });

                const errorList = errors.map((deviceError) => {
                    const deviceErrorDeviceType = dataService
                        .getData('deviceTypes')
                        .find((deviceType: any) => deviceType.id === deviceError.device_type);
                    return `<li>Module address: ${deviceError.module_address} (deviceType: ${
                        deviceErrorDeviceType ? deviceErrorDeviceType?.name : '-'
                    })</li>`;
                });
                const message = `Le deviceType des équipements suivants est incompatible avec ceux du modèle choisi (${templateDeviceTypes.join(
                    ', '
                )}<br />
        <ul>${errorList}</ul>`;

                if (message) {
                    // notif({
                    //   type: "error",
                    //   title: "Erreur",
                    //   message,
                    //   time: 10000,
                    // });
                }
                return { errors: errors, errorMessage: message };
            }
        });
    };

    checkDevices = (payload: any): Promise<[Device[], string[]]> => {
        return new Promise((resolve, reject) => {
            axios
                .post('/api/select-devices/', payload, {
                    headers: { 'Content-Type': 'application/json' }
                })
                .then(
                    (response: any) => {
                        resolve([response.data.devices, response.data?.warnings]);
                    },
                    (error: any) => {
                        const errorData = error?.response?.data;

                        if (errorData && error?.response?.status === 400 && errorData.errors) {
                            if (Array.isArray(errorData.errors)) {
                                reject(errorData.errors);
                            } else {
                                reject([errorData.errors]);
                            }
                            reject();
                        } else {
                            reject(['Une erreur serveur est survenue, merci de réessayer']);
                        }
                    }
                );
        });
    };

    operatorUseFTP = (operatorId: number): boolean => {
        const selectedOperator = dataService
            .getData('operators')
            .find((dataServiceOperator: any) => dataServiceOperator.id === operatorId);
        if (selectedOperator) {
            return selectedOperator.type === 'ftp';
        } else {
            // console.error("Operator `" + operatorId + "` not found");
        }
        return false;
    };

    operatorUseHRProtocol = (operatorId: number): boolean => {
        const HRProtocol = dataService.getData('protocols').find((protocol: any) => {
            return protocol.name === 'Homerider';
        });
        if (!HRProtocol) {
            // console.error("Protocol with name `Homerider` not found");
            return false;
        }

        const selectedOperator = dataService
            .getData('operators')
            .find((dataServiceOperator: any) => dataServiceOperator.id === operatorId);
        if (selectedOperator) {
            return selectedOperator.protocol === HRProtocol.id;
        }
        return false;
    };

    getDevicesProtocols = (devices: Device[]): number[] => {
        let protocols: number[] = [];
        if (devices && devices.length) {
            protocols = devices
                .map((device) => device?.protocol)
                .filter((protocol: number | undefined) => protocol !== undefined);
        }
        return _.uniq(protocols);
    };

    getDevicesOperators = (devices: Device[]): number[] => {
        let operators: number[] = [];
        if (devices && devices.length) {
            operators = devices
                .map((device) => device?.operator)
                .filter((operator: number | undefined) => operator !== undefined);
        }
        return _.uniq(operators);
    };

    getCampaignProtocols = (devices: Device[], operatorId: number): number[] => {
        const deviceProtocols = this.getDevicesProtocols(devices || []);
        const operator = operatorId ? dataService.getOperator(operatorId) : null;

        if (operator === null) {
            return deviceProtocols;
        } else {
            if (operator?.protocol) {
                return [operator.protocol];
            }
        }

        return [];
    };
}

export const campaignService = new CampaignService();
