import React from "react";
import { BirdzDialog, useDialog, AppPaper, StyledTitle, FormField, Item, BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { Link } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import Axios from "axios";
import { Button, Grid, IconButton } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import dataService from "../Common/Services/dataService";
import { Delete, Edit } from "@mui/icons-material";
import ListPageWrapper from "../Tools/ListPageWrapper";

const Messages = (params: any) => {
  let endpoint = "/api/messages/";
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  let formFields: FormField[] = [
    {
      name: "title",
      type: "text",
      label: "Titre message",
      validation: Yup.string().required(
        "Vous devez renseigner le titre du message"
      ),
    },
    {
      name: "type",
      type: "select",
      label: "Type message",
      options: {
        values: dataService.getSelectData("messageTypes"),
        identifier: "label",
        label: "label",
      },

    },
    {
      name: "content",
      type: "richtext",
      label: "Contenu message",
      validation: Yup.string().required(
        "Vous devez renseigner le contenu du message"
      ),
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, label: "Actif" },
          { value: false, label: "Inactif" },
        ],
      },
      defaultValue: true,
      permissions: ["IS_ACTIVE"],
    },
    {
      name: "updated_at",
      type: "readonly",
      label: "Dernière modification",
      transform: (value: any) => {
        let date =
          value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];

  let listFields = [
    {
      name: "title",
      label: "Titre message",
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      options: { width: "100px" },
      orderable: true,
    },
    {
      name: "updated_at",
      label: "Date de modification",
      transform: (value: any) => {
        return (
          (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
        );
      },
      orderable: true,
      options: { width: "220px" },
    },
  ];

  let searchFields = [
    {
      name: "title",
      label: "Titre message",
      options: {
        identifier: "title",
        source: "/api/messages/",
        label: "title",
        isMulti: false,
      },
    },
  ];

  let actions = [
    {
      name: "edit",
      render: (value: any) => {
        return <IconButton component={Link} to={`/admin/messages/edit/${value.id}`} id={'edit-message-button'}>
          <Edit fontSize="small" />
        </IconButton>
      }
    },
    {
      name: "delete-message",
      render: (model: any) => {
        return <IconButton id={'delete-message-button'} onClick={() => {
          confirmDialog({
            title: "Supprimer le message",
            content: "Êtes-vous sûr de vouloir supprimer ce message?",
            onValidate: () => {
              Axios.delete(`${endpoint}${model.id}/`).then(
                () => {
                  notif({
                    content: "Le message a été supprimé avec succès",
                    type: "success",
                  });
                  document.location.reload();
                },
                () => {
                  notif({
                    content: "Une erreur est survenue lors de la suppression",
                    type: "error",
                  });
                }
              )
              closeDialog();
            },
            onCancel: () => {
              closeDialog();
            }
          })
        }}><Delete fontSize="small" />
        </IconButton>
      }
    },
  ];

  return (
    <AppPaper>
      {params.action === "list" && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <StyledTitle>Liste des messages</StyledTitle>
            </Grid>
            <Grid item>
              <Link to="/admin/messages/add">
                <Button variant="contained" sx={{ mt: 3 }} id={'add-message-button'}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un message
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPageWrapper
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {params.action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un message"
        />
      )}
      {params.action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier le message"
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default Messages;