import { useState } from 'react';
import EncoderService from './encoderService';

const useEncoderService = () => {
    const [encoderService] = useState(new EncoderService());
    return {
        encoderService
    };
};

export default useEncoderService;
