import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import dataService from '../Common/Services/dataService';
import _ from 'lodash';
import { Device } from '../types';
import { Grid } from '@mui/material';
import useEncoderService from '../Common/Services/useEncoderService';

type CampaignCodecType = {
    onChange(codec: string): void;
    codec: string;
    label?: string;
    type: string;
    filterProtocol?: number;
    filterDeviceTypes?: number[];
    showAll?: boolean;
};

export type CodecsPath = {
    [deviceType: string]: string;
};

export const getCodecsPathByDeviceType = (devices: Device[], codecPath: string): CodecsPath => {
    const codecs_path: CodecsPath = {};
    _.uniq(
        devices?.map((device) => {
            return device.device_type;
        })
    ).forEach((deviceType: any) => {
        codecs_path[deviceType] = codecPath;
    });
    return codecs_path;
};

export const getCodecsPath = (codecPaths: CodecsPath): string => {
    if (!codecPaths) return '';
    return Object.values(codecPaths)[0] || '';
};

export const CampaignCodec = ({
    onChange,
    codec,
    label,
    type,
    filterProtocol,
    filterDeviceTypes,
    showAll = false
}: CampaignCodecType) => {
    const { encoderService } = useEncoderService();
    const [selectedCodec, setSelectedCodec] = useState<any>(null);
    const [codecs, setCodecs] = useState<any[]>(
        encoderService.getEncoders({ type }).map((encoder: any) => {
            return { value: encoder.id, label: encoder.label };
        })
    );

    useEffect(() => {
        if (!selectedCodec && codec) {
            let foundCodec = codecs.filter((foundCodecItem: any) => foundCodecItem.value === codec);
            if (foundCodec.length === 1) {
                setSelectedCodec(foundCodec[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codec]);

    useEffect(() => {
        let foundCodecs: any[];
        if (!showAll) {
            const protocol = filterProtocol ? dataService.getProtocol(filterProtocol) : undefined;
            foundCodecs = encoderService
                .getEncoders({
                    type,
                    protocol: protocol?.name,
                    device_type: filterDeviceTypes
                        ? filterDeviceTypes.map((deviceType) => {
                              return dataService.getDeviceType(deviceType);
                          })
                        : undefined
                })
                .map((foundCodec: any) => {
                    foundCodec.value = foundCodec?.id;
                    return foundCodec;
                });
        } else {
            foundCodecs = encoderService.getEncoders({ type }).map((encoder: any) => {
                return { value: encoder.id, label: encoder.label };
            });
        }

        if (!_.isEqual(foundCodecs, codecs)) {
            setCodecs(foundCodecs);
        }

        //eslint-disable-next-line
    }, [filterProtocol, filterDeviceTypes, showAll, codecs, type]);

    return (
        <Grid container alignItems={'center'} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={4}>
                {label || 'Codec'}
            </Grid>
            <Grid item xs={8}>
                <Select
                    id={'campaign-codec-select'}
                    options={codecs}
                    onChange={(selectedCodec: any) => {
                        setSelectedCodec(selectedCodec);
                        onChange(selectedCodec.value);
                    }}
                    value={selectedCodec}
                    loadingMessage={() => 'Chargement en cours...'}
                    noOptionsMessage={() => 'Aucune valeur à sélectionner'}
                    placeholder=""
                />
            </Grid>
        </Grid>
    );
};
