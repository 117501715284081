import { Grid, TextField } from '@mui/material';
import React from 'react';

type CampaignPortProps = {
    onChange(port: number): void;
    value?: number;
};

export const CampaignPort = ({ onChange, value }: CampaignPortProps) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={4}>
                        Port
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id={'campaign-port-input'}
                            type={'number'}
                            size="small"
                            fullWidth
                            onChange={(e: any) => {
                                onChange(e.target.value);
                            }}
                            value={value || 0}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                        <small>(si non défini, utilisera le port par défaut)</small>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
