import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useIsLoadingContext } from '../contexts/IsLoadingContext';
import { isArray } from 'lodash';
import { getCampaignNumber } from '../utils';

const useRequestStatus = () => {
    const { setIsLoading } = useIsLoadingContext();
    const [notification, setNotification] = useState<null | {
        content: any;
        type: 'warning' | 'success' | 'info' | 'error';
    }>(null);
    const numberOfRequestsPending = useRef(0); //ref to track number of request without triggering re-renders on value change

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            (config) => {
                numberOfRequestsPending.current++;
                setIsLoading(!('component' in config && config.component === 'ListPageWrapper')); // avoid re-renders when searching/filtering in ListPageWrapper
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        const responseInterceptor = axios.interceptors.response.use(
            (response) => {
                numberOfRequestsPending.current--;
                if (numberOfRequestsPending.current === 0) {
                    setIsLoading(false);
                }
                return response;
            },
            (error) => {
                if (error.response.data) {
                    Object.entries(error.response.data).forEach(([key, value]) => {
                        if (
                            key === 'campaign' &&
                            isArray(value) &&
                            value.includes(
                                'Sélectionnez un choix valide. Ce choix ne fait pas partie de ceux disponibles.'
                            )
                        ) {
                            let displayCampaignNumber = '';
                            const campaignNumber = getCampaignNumber(error.config.url);
                            if (campaignNumber) displayCampaignNumber = `n° ${campaignNumber} `;
                            setNotification({
                                content: `Attention, la campagne ${displayCampaignNumber} nexiste pas`,
                                type: 'warning'
                            });
                        }
                    });
                }
                numberOfRequestsPending.current--;
                if (numberOfRequestsPending.current === 0) {
                    setIsLoading(false);
                }
                return Promise.reject(error);
            }
        );

        //Cleanup on unmount
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
            setNotification(null);
        };
    }, [setIsLoading]);

    return { notification, setNotification };
};

export default useRequestStatus;
