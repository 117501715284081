import { Box, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useDebounce from "../../../../../hooks/useDebounce";
import { SearchField } from "@applications-terrains/birdz-react-library";

const CustomTextField = ({
    onSearch,
    field,
    placeholder,
    noOptions,
    ...props
}: {
    onSearch: (search: string) => void;
    placeholder: string;
    noOptions: boolean;
    field: SearchField;
}) => {
    const [inputValue, setInputValue] = useState('');
    const debouncedValue = useDebounce(inputValue, 300); // Déclencher après 300ms d'inactivité

    useEffect(() => {
        onSearch(debouncedValue);
    }, [debouncedValue, onSearch]);

    return (
        <Box sx={{ position: 'relative' }}>
            <TextField
                label={field.label}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
                placeholder={placeholder}
                {...props}
            />
            {noOptions && (
                <Paper
                    sx={{ position: 'absolute', height: '52px' }}
                    className="w-100 d-flex align-items-center">
                    <Box
                        sx={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            pl: 2,
                            fontSize: '1rem',
                            height: '20px'
                        }}>
                        aucun résultat
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

export default CustomTextField