import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Campaign, CampaignType } from '../components/types';
import { useLocation } from 'react-router-dom';

type DuplicateType = { purpose: {encrypted_frame: null | string, device: { [key: string]: unknown }}; campaign: Campaign } | null;
type ClickStatusType = 'click' | 'clicked' | null;
type CampaignContextType = {
    clickToCheckDevices: ClickStatusType; //force click on button (CampaignDevicesValidationButton.tsx) to check devices when duplication occurs
    setClickToCheckDevices: (value: ClickStatusType) => void;
    duplicate: DuplicateType;
    setDuplicate: (value: DuplicateType) => void;
    hasFrameTemplate: boolean | null;
    setHasFrameTemplate: (value: boolean | null) => void;
};

const CampaignContext = createContext<CampaignContextType | undefined>(undefined);

export const useCampaignContext = () => {
    const context = useContext(CampaignContext);

    if (context === undefined) {
        throw new Error('useCampaignContext must be used with a CampaignContext');
    }

    return context;
};

export const CampaignContextProvider = ({ children }: { children: ReactNode }) => {

    const [duplicate, setDuplicate] = useState<DuplicateType>(null);
    const [hasFrameTemplate, setHasFrameTemplate] = useState<boolean | null>(null);
    const [clickToCheckDevices, setClickToCheckDevices] = useState<ClickStatusType>(null);
    const location = useLocation();

    const resetCampaignContextToDefault = () => {
        setDuplicate(null);
        setClickToCheckDevices(null);
        setHasFrameTemplate(false)
    };

    useEffect(() => {
        const campaignType = location.pathname.replace('/campaigns/', '') as CampaignType;
        if (
            duplicate &&
            (!location.pathname.includes('campaigns') || duplicate.campaign.type !== campaignType)
        )
            resetCampaignContextToDefault();
    }, [location, duplicate]);

    const value = {
        duplicate,
        setDuplicate,
        clickToCheckDevices,
        setClickToCheckDevices,
        hasFrameTemplate,
        setHasFrameTemplate,
    };

    return (
        <CampaignContext.Provider value={value}>
            {children}
        </CampaignContext.Provider>
    );
};
