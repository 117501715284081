import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkContractsList from '../LinkContractsList';
import { Chip } from '@mui/material';
import { PiFileXls } from 'react-icons/pi';
import ListPageWrapper from '../../Tools/ListPageWrapper';

const AppendLinkContractsSelection = () => {
    const [filterContracts, setFilterContracts] = useState<string[]>();
    const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]); // just for selectedItems attribute ListPage
    const [listingIsVisible, setListingVisibility] = useState<boolean>(true);
    const navigate = useNavigate();

    const refreshListPage = () => {
        setListingVisibility(false);
        setTimeout(() => {
            setListingVisibility(true);
        });
    };

    return (
        <>
            <LinkContractsList
                endpoint={`/api/racsup/renfo/`}
                selectedContracts={selectedContracts}
                onFilterContracts={(contracts: string[]) => {
                    setFilterContracts(contracts);
                    refreshListPage();
                }}
                onCreateLink={() => {
                    navigate('/racsup/append-link/' + selectedContracts.join(','));
                }}>
                {listingIsVisible && (
                    <ListPageWrapper
                        name=""
                        endpoint={`/api/racsup/renfo/`}
                        filters={`${filterContracts ? '&contract__in=' + filterContracts.join(',') : ''}`}
                        fields={[
                            {
                                name: 'contract',
                                label: 'Contrat',
                                orderable: true
                            },
                            {
                                name: 'contract_label',
                                label: 'Libellé',
                                orderable: true
                            },
                            {
                                name: 'eligible_count',
                                label: 'Nb de module(s) ou répéteur(s) raccordable(s)',
                                orderable: true
                            },
                            {
                                name: 'excluded_count',
                                label: 'Nb de module(s) ou répéteur(s) exclu(s)',
                                orderable: true
                            },
                            {
                                name: 'modules_count',
                                label: 'Nb total de module(s) ou répéteur(s)',
                                orderable: true
                            },
                            {
                                name: 'repeaters_count',
                                label: 'Nb de répéteur(s)',
                                orderable: true
                            },
                            {
                                name: 'concentrators_count',
                                label: 'Nb de concentrateur(s)',
                                orderable: true
                            }
                        ]}
                        exportButtons={[
                            {
                                name: 'Exporter en xlsx',
                                fileName: 'Export des contrats renforcement.xlsx',
                                type: 'xlsx',
                                icon: <PiFileXls />,
                                id: 'appendedContract-export-button'
                            }
                        ]}
                        paginationOptions={{ pageSize: 100 }}
                        onSelectItems={(selectedContracts: any[]) => {
                            setSelectedContracts(
                                selectedContracts.map(
                                    (selectedContract) => selectedContract.contract
                                )
                            );
                            setSelectedItems(selectedContracts);
                        }}
                        selectedItems={selectedItems}
                        displaySelectedItems={(selectedItems: any[]) => {
                            return (
                                <div>
                                    Contrat{selectedItems.length > 1 ? 's' : ''} sélectionné
                                    {selectedItems.length > 1 ? 's' : ''}:{' '}
                                    {selectedItems.map((selectedContract, index) => (
                                        <Chip
                                            key={selectedContract.contract + index}
                                            size="small"
                                            label={selectedContract.contract}
                                            sx={{ mr: 1 }}
                                        />
                                    ))}
                                </div>
                            );
                        }}
                        disableRow={(data: { eligible_count: number }) =>
                            data?.eligible_count === 0
                        }
                    />
                )}
            </LinkContractsList>
        </>
    );
};

export default AppendLinkContractsSelection;
