import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

type CampaignMultiAntennaProps = {
    onChange(isMultiAntenna: boolean): any;
};

export const CampaignMultiAntenna = ({ onChange }: CampaignMultiAntennaProps) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    id={'campaign-multi-antenna-checkbox'}
                    onChange={(e: any) => {
                        onChange(e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
            label="Envoi sur 2 antennes"
            sx={{
                '& .MuiSvgIcon-root': { fontSize: 18 },
                '& .MuiFormControlLabel-label': { fontSize: '15px' }
            }}
        />
    );
};
