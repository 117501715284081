import { FocusEvent, MouseEventHandler } from 'react';
import { components } from 'react-select';
import Createable from 'react-select/creatable';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { SortableSelectProps } from './types';

const SortableMultiValue = SortableElement((props: any) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
});
const SortableMultiValueLabel = SortableHandle((props: any) => (
    <components.MultiValueLabel {...props} />
));
const Sortable = SortableContainer(Createable) as any;

const SortableSelect = ({
    options = [],
    values = [],
    setValues,
    hideMenu = false,
    ...rest
}: SortableSelectProps) => {
    let components: any = {
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel
    };

    if (hideMenu)
        components = {
            ...components,
            Menu: () => <></>,
            DropdownIndicator: () => <></>,
            IndicatorSeparator: () => <></>
        };

    //https://felixgerschau.com/react-typescript-onblur-event-type/
    const handleBlur = (event: FocusEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (value && !values.find((el) => el.value === value)) {
            setValues([...values, { value, label: value }]);
        }
    };

    return (
        <Sortable
            id={'sortable-select'}
            useDragHandle
            // react-sortable-hoc props:
            axis="xy"
            onSortEnd={({ oldIndex, newIndex }: any) => {
                function arrayMove<T>(array: readonly T[], from: number, to: number) {
                    const slicedArray = array.slice();
                    slicedArray.splice(
                        to < 0 ? array.length + to : to,
                        0,
                        slicedArray.splice(from, 1)[0]
                    );
                    return slicedArray;
                }
                const newValue = arrayMove(values, oldIndex, newIndex);
                setValues(newValue);
            }}
            distance={4}
            // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
            getHelperDimensions={({ node }: any) => node.getBoundingClientRect()}
            // react-select props:
            isMulti
            options={options}
            value={values}
            onChange={(selectedOptions: any) => setValues(selectedOptions)}
            components={components}
            allowCreateWhileLoading={true}
            closeMenuOnSelect={false}
            style={{ marginBottom: '10px' }}
            onBlur={handleBlur}
            {...rest}
        />
    );
};

export default SortableSelect;
