import moment from 'moment';
import DisplayCampaignsName from './DisplayCampaignsName';
import { useState } from 'react';
import ListPageWrapper from '../Tools/ListPageWrapper';
import { SearchForm } from '@applications-terrains/birdz-react-library';

const HrSendings = () => {
    const [filters, setFilters] = useState<string>('');

    return (
        <>
            <SearchForm
                onSubmit={setFilters}
                fields={[
                    {
                        name: 'concentrator',
                        label: 'Concentrateur',
                        type: 'text'
                    }
                ]}
            />
            <ListPageWrapper
                filters={filters}
                endpoint={`/api/hr-sendings/`}
                fields={[
                    { name: 'concentrator', label: 'Concentrateur' },
                    {
                        name: 'sent_date',
                        label: "Date d'envoi ciblée",
                        transform: (date: string) => {
                            return moment(date).format('DD/MM/YYYY à HH:mm:ss');
                        }
                    },
                    {
                        name: 'campaigns_purposes_count',
                        label: 'Nb de trames total campagne',
                        transform: (stat: number) => {
                            return stat || '0';
                        }
                    },
                    {
                        name: 'sent_purposes_count',
                        label: 'Nb de trames déposées sur le CR',
                        transform: (stat: number) => {
                            return stat || '0';
                        }
                    },
                    {
                        name: 'campaigns',
                        label: 'Campagnes associées',
                        transform: (row: { id: number; name: string }[]) => {
                            return <DisplayCampaignsName campaigns={row} />;
                        }
                    }
                ]}
                displayPaginationOptions={true}
            />
        </>
    );
};

export default HrSendings;
