import { useEffect, useState } from 'react';

const useCountdown = (action: () => void) => {
    const [count, setCount] = useState<number>(3);

    useEffect(() => {
        const counter = setTimeout(() => setCount(count - 1), 1000);
        if (count <= 0) {
            action();
            clearTimeout(counter);
        }
        return () => {
            clearTimeout(counter);
        };
    }, [count, action]);
    return count;
};

export default useCountdown;
