import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { campaignService } from '../CampaignService';
import CampaignDeviceErrors from '../CampaignDeviceErrors';
import CampaignDeviceWarning from '../CampaignDeviceWarning';
import CampaignDevicesString from '../CampaignUploadDevicesList/CampaignDevicesString';
import { Device } from '../../types';
import CampaignDevicesValidationButton from '../CampaignUploadDevicesList/CampaignDevicesValidationButton';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TextField
} from '@mui/material';
import {
    StyledTableBodyRow,
    StyledTableHeaderRow
} from '@applications-terrains/birdz-react-library';
import useExtendableList from '../../../hooks/useExtendableList';

type CampaignDeviceBroadcastProps = {
    onChange(devices: Device[], broadcastCode: string, devicesString: string): void;
    operatorId: number;
};

export const CampaignDeviceBroadcast = ({ onChange, operatorId }: CampaignDeviceBroadcastProps) => {
    const { t } = useTranslation();

    const [devices, setDevices] = useState<Device[]>([]);
    const [broadcastCode, setBroadcastCode] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [devicesString, setDevicesString] = useState<string>('concentrator\n');
    const [errorDevices, setErrorDevices] = useState<string[]>();
    const [warningDevices, setWarningDevices] = useState<string[]>();
    const [checkInProgress, setCheckInProgress] = useState<boolean>(false);
    const { listToDisplay, SeeMore } = useExtendableList(devices);

    const sendDevices = (devicesToSend: Device[]) => {
        onChange(devicesToSend, broadcastCode, devicesString);
    };

    const checkDevices = () => {
        setCheckInProgress(true);

        campaignService
            .checkDevices({
                devices_text: devicesString,
                campaign_type: 'broadcast',
                broadcast_address: address,
                operator: operatorId
            })
            .then(
                ([devices, warningDevices]) => {
                    setDevices(devices);
                    setErrorDevices([]);
                    setWarningDevices(warningDevices);
                    sendDevices(devices);
                },
                (errors: string[]) => {
                    setErrorDevices(errors);
                    setWarningDevices([]);
                }
            )
            .finally(() => {
                setCheckInProgress(false);
            });
    };

    return (
        <>
            <CampaignDevicesString
                onChange={(devicesString: string) => {
                    setDevicesString(devicesString);
                }}
                operatorId={operatorId}
                campaignType={'broadcast'}
            />
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    Code broadcast
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        id={'broadcast-campaign-code-input'}
                        type={'text'}
                        size="small"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setBroadcastCode(event.target.value);
                        }}
                        value={broadcastCode}
                        required
                    />
                </Grid>

                <Grid item xs={3}>
                    Adresse générique
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        id={'broadcast-campaign-address-input'}
                        type={'text'}
                        size="small"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setAddress(event.target.value);
                        }}
                        value={address}
                        required
                    />
                </Grid>
            </Grid>

            <CampaignDeviceErrors errors={errorDevices} />
            <CampaignDeviceWarning warnings={warningDevices} />

            <CampaignDevicesValidationButton
                disabled={!devicesString || !broadcastCode || !address || checkInProgress}
                onClick={() => checkDevices()}
                isLoading={checkInProgress}
            />

            {devices && devices.length > 0 && (
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <StyledTableHeaderRow>
                                <TableCell>{t('module_address')}</TableCell>
                                <TableCell>{t('concentrator')}</TableCell>
                            </StyledTableHeaderRow>
                        </TableHead>
                        <TableBody>
                            {(listToDisplay as Device[]).map((device) => {
                                return (
                                    <StyledTableBodyRow
                                        key={device.module_address + device.module_address}>
                                        <TableCell>{device?.module_address}</TableCell>
                                        <TableCell>{device?.concentrator}</TableCell>
                                    </StyledTableBodyRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <SeeMore />
                </TableContainer>
            )}
        </>
    );
};
