import React, { useEffect, useState } from 'react';
import moment from 'moment';
import dataService from '../../../Common/Services/dataService';
import { Box, Chip } from '@mui/material';
import './CampaignsList.scss';
import {
    Description,
    Language,
    Person,
    Ballot,
    CalendarToday,
    Schedule,
    SvgIconComponent
} from '@mui/icons-material';
import DisplayStatus from './DisplayStatus';
import { CampaignListItem, CampaignsListProps } from './types';
import ExtendableChip from './ExtendableChip';
import DisplayActions from './DisplayActions';
import { useSearchCampaignsAndPurposesContext } from '../SearchCampaignsAndPurposesContext';
import BTable from '../../../Tools/Table/BTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { NotifObjType } from '../../../Tools/NotifWithCountdown';

const FONT_SIZE = '0.9rem';

const DisplayCampaign = ({ campaign }: { campaign: CampaignListItem }) => {
    const operator = dataService.getOperator(campaign.operator);
    return (
        <Box
            sx={{
                minHeight: '80px',
                maxWidth: '550px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            <div className="d-flex">
                <ExtendableChip
                    label={campaign.name}
                    title={'name'}
                    icon={<Ballot color="primary" />}
                    style={{
                        position: 'relative',
                        border: 'none',
                        backgroundColor: 'white'
                    }}
                    maxNbOfChar={50}
                />
                {operator?.name && (
                    <ExtendableChip
                        label={operator?.name}
                        title="Opérateur utilisé"
                        icon={<Language style={{ fontSize: '16px', color: '#253053' }} />}
                        maxNbOfChar={campaign.name.length > 40 ? 8 : 100}
                        style={{
                            marginLeft: '0.5rem',
                            color: '#253053',
                            border: '1px solid #253053',
                            backgroundColor: 'white'
                        }}
                    />
                )}
            </div>

            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                {campaign.template_name && (
                    <ExtendableChip
                        label={campaign.template_name}
                        title="Modèle utilisé"
                        icon={<Description style={{ fontSize: '16px', color: '#616161' }} />}
                        maxNbOfChar={50}
                    />
                )}
                <Chip
                    label={campaign.created_by__name}
                    title="Créateur de la campagne"
                    size="small"
                    icon={<Person />}
                    style={{ fontSize: FONT_SIZE, border: 'none' }}
                    sx={{ ml: 1 }}
                    variant="outlined"
                />
            </Box>
        </Box>
    );
};

export const CampaignsList = ({ archived = false }: CampaignsListProps) => {
    const [refresh, setRefresh] = useState<boolean>(false);
    const { campaignsFiltersString: filters } = useSearchCampaignsAndPurposesContext();
    const { search } = useLocation();
    const navigate = useNavigate();
    const { notif, notifOptions } = useNotif();

    const campaignActions = [
        {
            name: 'actions',
            render: (campaign: any) => {
                return <DisplayActions campaign={campaign} refreshComponent={refreshComponent} />;
            }
        }
    ];

    const refreshComponent = (notifObj: NotifObjType) => {
        setRefresh(true);
        notif(notifObj);
    };

    useEffect(() => {
        refresh && setRefresh(false);
    }, [refresh]);

    useEffect(() => {
        if (search.includes('&refresh=true')) {
            setRefresh(true);
            navigate('/sendings/sent-list?tab=campaigns');
        }
    }, [search, navigate]);

    return (
        <>
            <BTable
                refresh={refresh}
                model="campaigns"
                fields={[
                    {
                        name: 'custom',
                        label: 'Campagne',
                        // options: { width: '100px' },
                        transform: (_: any, campaign: any) => {
                            return <DisplayCampaign campaign={campaign} />;
                        }
                    },
                    {
                        name: 'id',
                        label: 'Id',
                        className: 'text-center',
                        options: { width: '50px' }
                    },
                    {
                        name: 'created_at',
                        label: 'Date',
                        // className: 'text-center',
                        options: { width: '50px' },
                        transform: (value: any) => {
                            let date = value && moment.unix(value).format('DD/MM/YYYY');
                            let time = value && moment.unix(value).format('HH:mm:ss');
                            const Item = ({
                                text,
                                Icon
                            }: {
                                text: string;
                                Icon: SvgIconComponent;
                            }) => (
                                <div className="d-flex align-items-center">
                                    {
                                        <Icon
                                            color="primary"
                                            style={{ fontSize: '16px', color: '#616161' }}
                                            sx={{ mr: 1 }}
                                        />
                                    }{' '}
                                    {text}
                                </div>
                            );
                            return date && time ? (
                                <div
                                    className="d-flex flex-column"
                                    style={{ fontSize: FONT_SIZE, gap: 1 }}>
                                    <Item text={date} Icon={CalendarToday} />
                                    <Item text={time} Icon={Schedule} />
                                </div>
                            ) : (
                                ''
                            );
                        },
                        orderable: true
                    },
                    {
                        name: 'state',
                        label: 'Statut',
                        orderable: false,
                        transform: (_, campaign: CampaignListItem) => {
                            return <DisplayStatus campaign={campaign} />;
                        },
                        options: {
                            width: '180px'
                        }
                    },
                    {
                        name: 'total',
                        label: 'Total intentions',
                        orderable: false,
                        className: 'text-center',
                        options: { width: '50px' },
                        transform: (_, campaign: CampaignListItem) => {
                            return campaign.purposes_count || '-';
                        }
                    }
                ]}
                endpoint={
                    archived
                        ? '/api/campaigns/?archived=true'
                        : '/api/campaigns/' + filters.replace('&', '?')
                }
                actions={archived === false ? campaignActions : []}
            />
            <BirdzNotif options={notifOptions} />
        </>
    );
};

export default CampaignsList;
