import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import fr from 'date-fns/locale/fr';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import EligibleModules, { EligibleModule } from './EligibleModules';
import _ from 'lodash';
import axios from 'axios';
import './LinkForm.scss';
import {
    Alert,
    Box,
    Button,
    Grid,
    LinearProgress,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { EligibleModulesFilters } from './EligibleModulesSearch';
import {
    AppPaper,
    AutocompleteField,
    FormField,
    StyledTableBodyRow,
    StyledTableHeaderRow
} from '@applications-terrains/birdz-react-library';
import { authService } from '../..';
import { ScheduleOptions } from '../Common/ScheduleOptions/ScheduleOptions';
import dataService from '../Common/Services/dataService';
import NotifWithCountdown from '../Tools/NotifWithCountdown';
import { useNavigate } from 'react-router-dom';

registerLocale('fr', fr);
setDefaultLocale('fr');

export type LinkFormValue = {
    description?: string;
    operator?: any;
    sendingSchedule?: 'now' | 'schedule';
    scheduledDate?: Date;
};

type LinkFormProps = {
    contracts: string[];
    linkType: 'primo' | 'renfo' | 'rerac' | 'net';
};

type Instructions = {
    [contract: string]: Instruction;
};
type Instruction = {
    name?: string;
    selectedModules: any[];
    eligibleModules: any[];
};

type WarningsInstructions = {
    [contract: string]: string[];
};

type CreatedInstruction = {
    name: string;
    modules_count: number;
    id: number;
};

export type SelectedModules = { [contract: string]: EligibleModule[] };
export type EligibleModulesType = { [contract: string]: EligibleModule[] };

export const LinkForm = ({ contracts, linkType }: LinkFormProps): any => {
    const navigate = useNavigate();
    const [isReady, setIsReady] = useState(false);
    const [saveIsLoading, setSaveIsLoading] = useState(false);

    const [instructions, setInstructions] = useState<Instructions>({});
    const [contractInformations, setContractInformations] = useState<any>([]);

    const [createdInstructions, setCreatedInstructions] = useState<CreatedInstruction[]>();
    const [warningsInstructions, setWarningInstructions] = useState<WarningsInstructions>();

    const [filtersModules, setFilterModules] = useState<EligibleModulesFilters>({});
    const [eligibleModules, setEligibleModules] = useState<EligibleModulesType>({});
    const [selectedModules, setSelectedModules] = useState<SelectedModules>({});

    const [valueTab, setValueTab] = React.useState(0);
    const [availableProtocols, setAvailableProtocols] = useState<any[]>([]);
    const [backupAvailableProtocols, setBackupAvailableProtocols] = useState<any[]>([]);

    const handleChangeTab = (event: any, newValueTab: number) => {
        setValueTab(newValueTab);
    };
    const CHUNKSIZE = 5;

    const loadContracts = async (contracts: string[], startIndex: number = 0) => {
        // Get a chunk of 5 contracts
        const chunk = contracts.slice(startIndex, startIndex + CHUNKSIZE);

        // Load data for the chunk of contracts
        await Promise.allSettled(
            chunk.map((contract: string) => axios.get(`/api/racsup/${linkType}/${contract}/`))
        ).then(async (responses: any[]) => {
            const newEligibleModules: EligibleModulesType = {};
            const newSelectedModules: SelectedModules = {};
            setContractInformations((contractInformations: any) => {
                return [
                    ...contractInformations,
                    ...responses
                        .filter((response: any) => {
                            return response.status === 'fulfilled';
                        })
                        .map((response: any) => response?.value?.data)
                ];
            });

            const newInstructions = Object.assign({}, instructions);
            const newAvailableProtocols: string[] = [];

            responses.forEach((response: any) => {
                const responseData = response?.value?.data;
                if (responseData?.contract) {
                    if (responseData?.protocols) {
                        responseData?.protocols.forEach((protocol: any) => {
                            if (!newAvailableProtocols.includes(protocol)) {
                                newAvailableProtocols.push(protocol);
                            }
                        });
                    }

                    const contract = responseData?.contract;
                    if (typeof instructions[contract] === 'undefined') {
                        newInstructions[contract] = {
                            name: `${new Date().toISOString().replaceAll('-', '').substring(0, 8)}-${contract}-${authService.user?.email.split('@')[0]}-${linkType}`,
                            selectedModules: responseData?.eligible_modules,
                            eligibleModules: responseData?.eligible_modules
                        };
                        newEligibleModules[contract] = responseData?.eligible_modules || [];
                        newSelectedModules[contract] = responseData?.eligible_modules || [];
                    }
                }
            });

            setAvailableProtocols((availableProtocols) => {
                const uniqueProtocols = Array.from(
                    new Set([...availableProtocols, ...newAvailableProtocols])
                ).filter((protocol: string) => protocol !== null);
                setBackupAvailableProtocols(uniqueProtocols);
                return uniqueProtocols;
            });

            // if (!_.isEqual(instructions, newInstructions)) {
            setEligibleModules((eligibleModules) => {
                return { ...eligibleModules, ...newEligibleModules };
            });
            setSelectedModules((selectedModules) => {
                return { ...selectedModules, ...newSelectedModules };
            });
            setInstructions((instructions) => {
                return { ...instructions, ...newInstructions };
            });
            // }

            // If there are more contracts to load, load the next chunk
            if (startIndex + CHUNKSIZE < contracts.length) {
                await loadContracts(contracts, startIndex + CHUNKSIZE);
            }

            setIsReady(true);
        });
    };

    useEffect(() => {
        if (contracts) {
            // Start loading contracts
            loadContracts(contracts);
        }
        //eslint-disable-next-line
    }, [contracts]);

    const saveLink = (values: any) => {
        setSaveIsLoading(true);
        const allSavePromises: Promise<void>[] = [];

        for (const contract in instructions) {
            const instruction = instructions[contract];
            // demande de Patrice de transformer les devices avant envoi
            const selectedDevices = selectedModules[contract].map((selectedModule) => {
                return {
                    module_address: selectedModule.repeater,
                    affiliate: selectedModule.module_address,
                    concentrator: selectedModule.concentrator
                };
            });

            const payload: any = {
                contract,
                type: linkType.toUpperCase(),
                name: instruction.name,
                description: values?.description,
                operator: values?.operator?.value || null,
                devices: selectedDevices
            };
            if (availableProtocols.length > 0) {
                availableProtocols.forEach((protocolTechnicalCode: string) => {
                    if (!protocolTechnicalCode) return;
                    const scheduleField = `sending_schedule_${protocolTechnicalCode.toLocaleLowerCase()}`;
                    const scheduledDateField = `scheduled_date_${protocolTechnicalCode.toLocaleLowerCase()}`;
                    payload[scheduleField] = values[scheduleField];
                    payload[scheduledDateField] = values[scheduledDateField];
                });
            }

            if (selectedDevices.length > 0) {
                allSavePromises.push(axios.post('/api/racsup/create-instructions/', payload));
            }
        }

        Promise.allSettled(allSavePromises).then((responses) => {
            const created: CreatedInstruction[] = [];
            const warnings: WarningsInstructions = {};

            responses.forEach((response: any, index: number) => {
                const contract = Object.keys(instructions)[index];

                const data = response?.value?.data;
                if (data?.created && data?.created.length > 0) {
                    data?.created.forEach((createdInstruction: CreatedInstruction) => {
                        created.push(createdInstruction);
                    });
                }
                if (data?.warnings && data.warnings.length > 0) {
                    warnings[contract] = warnings[contract] || [];
                    data.warnings.forEach((warning: string) => {
                        warnings[contract].push(warning);
                    });
                }
            });

            setWarningInstructions(warnings);
            setCreatedInstructions(created);
            setSaveIsLoading(false);
        });
    };

    const defaultOperatorValue = { value: null, label: 'Sélection automatique' };

    return (
        <>
            {isReady && availableProtocols.length === 0 ? (
                <Alert severity="warning">
                    Aucun protocole n'a été trouvé dans le patrimoine pour les équipements de ce
                    contrat
                </Alert>
            ) : (
                <Formik
                    initialValues={{} as any}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        saveLink(values);
                    }}>
                    {({
                        values,
                        getFieldProps,
                        setFieldValue,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldTouched,
                        setSubmitting
                    }: any) => (
                        <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    Description
                                </Grid>
                                <Grid alignItems={'center'} item xs={8}>
                                    <TextField
                                        id={'linkForm-description-input'}
                                        type={'textarea'}
                                        fullWidth
                                        multiline
                                        rows={3}
                                        {...getFieldProps('description')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    Opérateur
                                </Grid>
                                <Grid alignItems={'center'} item xs={8}>
                                    <div
                                        className={
                                            (touched.operator || isSubmitting) && errors.operator
                                                ? 'select-container is-invalid'
                                                : 'select-container'
                                        }>
                                        <AutocompleteField
                                            field={
                                                {
                                                    options: {
                                                        source: '/api/lists/operators/?ordering=name&',
                                                        searchIsMulti: false,
                                                        isActive: true,
                                                        defaultOptions: [defaultOperatorValue]
                                                    },
                                                    name: 'operator'
                                                } as FormField
                                            }
                                            onSelect={(newOperator: any) => {
                                                setFieldValue('operator', newOperator);

                                                const foundOperator = dataService
                                                    .getData('operators')
                                                    .find((operator: any) => {
                                                        return operator.id === newOperator.value;
                                                    });

                                                if (foundOperator?.protocol) {
                                                    const operatorProtocol = dataService
                                                        .getData('protocols')
                                                        .find((protocol: any) => {
                                                            return (
                                                                protocol.id ===
                                                                foundOperator.protocol
                                                            );
                                                        });
                                                    if (operatorProtocol?.technical_code) {
                                                        setAvailableProtocols([
                                                            operatorProtocol.technical_code
                                                        ]);
                                                    }
                                                } else {
                                                    setAvailableProtocols(backupAvailableProtocols);
                                                }

                                                setTimeout(() => {
                                                    setFieldTouched('operator', true);
                                                });
                                            }}
                                            value={
                                                !values.operator
                                                    ? defaultOperatorValue
                                                    : values.operator
                                            }
                                        />
                                    </div>

                                    {(touched.operator || isSubmitting) && errors.operator && (
                                        <Typography sx={{ color: 'red' }}>
                                            Le champ opérateur est obligatoire
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item xs={4}>
                                    Planification
                                </Grid>
                                <Grid alignItems={'center'} item xs={8}>
                                    {availableProtocols.map(
                                        (protocolTechnicalCode: string, index: number) => {
                                            const protocol = dataService
                                                .getData('protocols')
                                                .find((protocol: any) => {
                                                    return (
                                                        protocol.technical_code ===
                                                        protocolTechnicalCode
                                                    );
                                                });

                                            const scheduleField = `sending_schedule_${protocolTechnicalCode.toLocaleLowerCase()}`;
                                            const scheduledDateField = `scheduled_date_${protocolTechnicalCode.toLocaleLowerCase()}`;
                                            return (
                                                <Fragment key={index + protocolTechnicalCode}>
                                                    <Box
                                                        fontWeight={500}
                                                        fontSize={15}
                                                        sx={{ mt: 1 }}>
                                                        {protocol?.name}
                                                    </Box>
                                                    <ScheduleOptions
                                                        onChange={(value: any) => {
                                                            setFieldValue(
                                                                scheduleField,
                                                                value?.type
                                                            );
                                                            setFieldValue(
                                                                scheduledDateField,
                                                                value?.scheduledDate
                                                            );
                                                        }}
                                                        value={{
                                                            type: values[scheduleField],
                                                            scheduledDate:
                                                                values[scheduledDateField]
                                                        }}
                                                        protocolId={protocol?.id}
                                                        displayLabel={false}
                                                    />
                                                </Fragment>
                                            );
                                        }
                                    )}
                                </Grid>
                            </Grid>

                            {!isReady && (
                                <Alert
                                    severity="info"
                                    sx={{
                                        '& .MuiAlert-message': {
                                            width: '100%'
                                        },
                                        mt: 2
                                    }}>
                                    {contractInformations.length} contrats chargés sur{' '}
                                    {contracts.length}
                                    <LinearProgress />
                                </Alert>
                            )}

                            {contractInformations.length > 0 && (
                                <Box>
                                    <Tabs
                                        value={valueTab}
                                        onChange={handleChangeTab}
                                        variant="scrollable"
                                        scrollButtons="auto">
                                        {contractInformations.map(
                                            (contractInformation: any, i: number) => {
                                                const contract: string =
                                                    contractInformation.contract;
                                                return (
                                                    <Tab
                                                        key={'contractInformation' + i}
                                                        label={`${contract} (${selectedModules[contract].length}/${eligibleModules[contract].length})`}
                                                    />
                                                );
                                            }
                                        )}
                                    </Tabs>
                                    <AppPaper sx={{ p: 2, pt: 2, mt: 1 }}>
                                        {contractInformations.map(
                                            (contractInformation: any, tabIndex: number) => {
                                                const contract: string =
                                                    contractInformation.contract;
                                                const instruction = instructions[contract];
                                                return (
                                                    <>
                                                        {tabIndex === valueTab && (
                                                            <Box key={contract}>
                                                                <div className="contract mt-3">
                                                                    Contrat : {contract} (
                                                                    {
                                                                        contractInformation.contract_label
                                                                    }
                                                                    )
                                                                </div>

                                                                <div className="name">
                                                                    Nom de chantier :{' '}
                                                                    <span>{instruction?.name}</span>
                                                                </div>

                                                                <div>
                                                                    Équipements :
                                                                    <ul>
                                                                        <li>
                                                                            Modules :{' '}
                                                                            {
                                                                                contractInformation?.modules_count
                                                                            }
                                                                        </li>
                                                                        <li>
                                                                            Répéteurs :{' '}
                                                                            {
                                                                                contractInformation?.repeaters_count
                                                                            }
                                                                        </li>
                                                                        <li>
                                                                            Concentrateurs :{' '}
                                                                            {
                                                                                contractInformation?.concentrators_count
                                                                            }
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div>
                                                                    <h3>Modules éligibles</h3>
                                                                    {instruction?.eligibleModules && (
                                                                        <EligibleModules
                                                                            contract={contract}
                                                                            allSelectedModulesByContract={
                                                                                selectedModules
                                                                            }
                                                                            allEligibleModulesByContract={
                                                                                eligibleModules
                                                                            }
                                                                            onSelectModules={(
                                                                                newSelectedModules: EligibleModule[]
                                                                            ) => {
                                                                                if (
                                                                                    !_.isEqual(
                                                                                        selectedModules[
                                                                                            contract
                                                                                        ],
                                                                                        newSelectedModules
                                                                                    )
                                                                                ) {
                                                                                    setSelectedModules(
                                                                                        {
                                                                                            ...selectedModules,
                                                                                            ...{
                                                                                                [contract]:
                                                                                                    newSelectedModules
                                                                                            }
                                                                                        } as SelectedModules
                                                                                    );
                                                                                }
                                                                            }}
                                                                            onFilterModules={(
                                                                                filters: any
                                                                            ) => {
                                                                                setFilterModules(
                                                                                    filters
                                                                                );
                                                                            }}
                                                                            filtersModules={
                                                                                filtersModules
                                                                            }
                                                                        />
                                                                    )}

                                                                    <h3
                                                                        style={{
                                                                            marginTop: '30px'
                                                                        }}>
                                                                        Modules exclus
                                                                    </h3>
                                                                    <TableContainer
                                                                        component={Paper}>
                                                                        <Table size="small">
                                                                            <TableHead>
                                                                                <StyledTableHeaderRow>
                                                                                    <TableCell>
                                                                                        Commune
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        Module
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        Concentrateur
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        Répéteur
                                                                                    </TableCell>
                                                                                </StyledTableHeaderRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {contractInformation
                                                                                    .excluded_modules
                                                                                    .length ? (
                                                                                    contractInformation.excluded_modules.map(
                                                                                        (
                                                                                            excludedModule: any
                                                                                        ) => {
                                                                                            return (
                                                                                                <StyledTableBodyRow
                                                                                                    key={
                                                                                                        excludedModule.module_address
                                                                                                    }>
                                                                                                    <TableCell>
                                                                                                        {
                                                                                                            excludedModule.commune
                                                                                                        }
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        {
                                                                                                            excludedModule.module_address
                                                                                                        }
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        {
                                                                                                            excludedModule.concentrator
                                                                                                        }
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        {
                                                                                                            excludedModule.repeater
                                                                                                        }
                                                                                                    </TableCell>
                                                                                                </StyledTableBodyRow>
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                ) : (
                                                                                    <StyledTableBodyRow>
                                                                                        <TableCell
                                                                                            colSpan={
                                                                                                4
                                                                                            }>
                                                                                            Aucun
                                                                                            module
                                                                                            exclu
                                                                                        </TableCell>
                                                                                    </StyledTableBodyRow>
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </div>
                                                            </Box>
                                                        )}
                                                    </>
                                                );
                                            }
                                        )}
                                    </AppPaper>
                                </Box>
                            )}

                            {createdInstructions && createdInstructions.length > 0 && (
                                <>
                                    <h3 className="mt-2">
                                        {createdInstructions.length} chantier
                                        {createdInstructions.length > 0 && 's'} créé
                                        {createdInstructions.length > 0 && 's'}:
                                    </h3>
                                    <Alert severity={'success'} className="mt-2">
                                        <ul>
                                            {createdInstructions.map(
                                                (createdInstruction: CreatedInstruction, index) => {
                                                    return (
                                                        <li key={createdInstruction.id + index}>
                                                            {createdInstruction.name} (nombre
                                                            modules:{' '}
                                                            {createdInstruction.modules_count})
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </Alert>
                                    <NotifWithCountdown
                                        action={() => navigate('/sendings/sent-list?tab=campaigns&refresh=true')}
                                        notifObj={{
                                            type: 'success',
                                            content: `${createdInstructions.length} chantier${createdInstructions.length > 1 ? 's' : ''} créé${createdInstructions.length > 1 ? 's' : ''} avec succès`
                                        }}
                                    />
                                </>
                            )}
                            {warningsInstructions &&
                                Object.keys(warningsInstructions).length > 0 && (
                                    <>
                                        <h3 className="mt-2">Avertissements:</h3>
                                        <Alert severity={'warning'} className="mt-2">
                                            <ul>
                                                {Object.keys(warningsInstructions).map(
                                                    (contract: string, contractIndex: number) => {
                                                        return (
                                                            <li key={contract + contractIndex}>
                                                                Contrat {contract}:
                                                                <ul>
                                                                    {warningsInstructions[
                                                                        contract
                                                                    ].map(
                                                                        (
                                                                            warning: string,
                                                                            warningIndex: number
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        contract +
                                                                                        warningIndex
                                                                                    }>
                                                                                    {warning}
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </Alert>
                                    </>
                                )}

                            <Box sx={{ textAlign: 'center', mt: 1 }}>
                                <Button
                                    id={'linkForm-submit-button'}
                                    variant="contained"
                                    type="submit"
                                    disabled={saveIsLoading}
                                    onClick={() => {
                                        setTimeout(() => {
                                            setSubmitting(true);
                                        });
                                    }}>
                                    Créer le{contracts.length > 1 && 's'} chantier
                                    {contracts.length > 1 && 's'}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default LinkForm;
