import { Grid, TextField } from '@mui/material';
import React from 'react';

type CampaignMaxDaysProps = {
    onChange(max_days: number): void;
    value?: number;
};

export const CampaignMaxDays = ({ onChange, value }: CampaignMaxDaysProps) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={4}>
                        Délai d'expiration
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id={'campaign-max-days-input'}
                            type={'number'}
                            size="small"
                            fullWidth
                            onChange={(e: any) => {
                                onChange(e.target.value);
                            }}
                            value={value || 0}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                        />
                        <small>(facultatif, à 7 jours par défaut)</small>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
