import React, { ReactNode, useEffect, useState } from 'react';
import NotifWithCountdown, { NotifObjType } from '../components/Tools/NotifWithCountdown';
import { useNavigate } from 'react-router-dom';

const useCampaignSuccess = () => {
    const navigate = useNavigate();
    const [successNotif, setSuccessNotif] = useState<ReactNode>(null);
    const [successObj, setSuccessObj] = useState<NotifObjType | null>(null);

    useEffect(() => {
        const cleanup = () => {
            setSuccessNotif(null);
            setSuccessObj(null);
        };
        if (successObj) {
            setSuccessNotif(
                <NotifWithCountdown
                    action={() => {
                        navigate('/sendings/sent-list?tab=campaigns&refresh=true');
                        cleanup();
                    }}
                    notifObj={successObj}
                />
            );
        }
    }, [successObj, navigate]);

    return { successNotif, setSuccessObj };
};

export default useCampaignSuccess;
