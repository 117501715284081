import React, { PropsWithChildren, useEffect, useState } from 'react';
import { SearchField, SearchFormValue } from '@applications-terrains/birdz-react-library';
import { Box, Button, IconButton, Accordion, AccordionDetails } from '@mui/material';
import { Formik, Form } from 'formik';
import './b-search-form.scss';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { getFilterString, removeFalsyValues } from '../../../utils';
import BSearchField from './Fields/BSearchField';
import { isArray } from 'lodash';

const FormBox = (props: PropsWithChildren) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridGap: 16
            }}>
            {props.children}
        </Box>
    );
};

const BSearchForm = ({
    fields,
    values,
    setSearchString,
    setSearchValues
}: {
    fields: SearchField[];
    values: SearchFormValue;
    setSearchString: (search: string) => void;
    setSearchValues: (values: SearchFormValue) => void;
}) => {
    const [expanded, setExpanded] = useState(false);

    // determine if form should be expanded from mount
    useEffect(() => {
        const seeMoreFields = fields.filter((field) => field.seeMore);
        const fieldsWithValueInForm: string[] = [];
        seeMoreFields.forEach((field) => {
            if (field.name in values.formValue) {
                const value = values.formValue[field.name];
                if ((isArray(value) && value.length > 0) || (!isArray(value) && value)) {
                    fieldsWithValueInForm.push(field.name);
                }
            }
        });
        setExpanded(fieldsWithValueInForm.length > 0);
    }, [fields, values]);

    // Map initial values from the context's formValue
    const initialValues = {
        ...fields.reduce(
            (acc, field) => {
                acc[field.name] = values.formValue[field.name] ?? (field.options?.values ? [] : '');
                return acc;
            },
            {} as Record<string, any>
        )
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values) => {
                setSearchString(getFilterString(removeFalsyValues(values)));
                setSearchValues({
                    formValue: values,
                    filterValues: values
                });
            }}>
            {({ values, setFieldValue }) => {
                return (
                    <Form>
                        <Box
                            className="form-container"
                            sx={{
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                pt: 3,
                                mb: 2
                            }}>
                            <FormBox>
                                {fields
                                    .filter((el) => !el.seeMore)
                                    .map((field) => (
                                        <BSearchField
                                            key={field.name}
                                            field={field}
                                            value={values[field.name]}
                                            setFieldValue={setFieldValue}
                                        />
                                    ))}
                            </FormBox>
                            <Accordion
                                elevation={0}
                                expanded={expanded}
                                onChange={() => setExpanded(!expanded)}
                                sx={{
                                    '::before': {
                                        display: 'none'
                                    }
                                }}>
                                <Box></Box>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <FormBox>
                                        {fields
                                            .filter((el) => el.seeMore)
                                            .map((field) => (
                                                <BSearchField
                                                    key={field.name}
                                                    field={field}
                                                    value={values[field.name]}
                                                    setFieldValue={setFieldValue}
                                                />
                                            ))}
                                    </FormBox>
                                </AccordionDetails>
                            </Accordion>
                            <Box
                                className="buttons-container w-100 d-flex justify-content-between align-items-center"
                                sx={{
                                    my: 2
                                }}>
                                <IconButton
                                    title={`${expanded ? 'moins' : 'plus'} de filtres`}
                                    onClick={() => setExpanded(!expanded)}>
                                    {expanded ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                </IconButton>
                                <Box className="d-flex" sx={{ gap: 1 }}>
                                    <Button
                                        onClick={() => {
                                            setSearchString('');
                                            setSearchValues({
                                                formValue: {},
                                                filterValues: {}
                                            });
                                        }}
                                        variant="outlined">
                                        Reinitialiser
                                    </Button>
                                    <Button type="submit" variant="contained">
                                        Rechercher
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default BSearchForm;
