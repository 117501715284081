import styled from 'styled-components';
import { useEffect, useId, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { truncateWithEllipsis } from '../../../../utils';

type ChipProps = {
    width: number;
    baseWidth: number;
};

type StyledBoxProps = {
    width: number;
};

type ExtendableChipProps = {
    label: string;
    title: string;
    icon?: JSX.Element;
    maxNbOfChar: number;
    style?: object;
};

const StyledChip = styled.div<ChipProps>`
    overflow: hidden;
    padding: 0 12px;
    height: 24px;
    display: flex;
    border-radius: 16px;
    align-items: center;
    white-space: nowrap;

    &:hover {
        .text {
            width: ${({ width }) => width}px;
            transition: width 0.5s ease-in-out;
        }
    }
    &:not(:hover) {
        .text {
            width: ${({ baseWidth }) => baseWidth}px;
            transition: width 0.5s ease-in-out;
        }
    }

    .text {
        padding-left: 0.25rem;
        width: ${({ baseWidth }) => baseWidth}px;
    }
`;

const StyledBox = styled(Box)<StyledBoxProps>`
    position: relative;
    min-height: 24px;
    width: ${({ width }) => width}px;
    font-size: 0.9rem;

    .absolute {
        position: absolute;
    }

    .fake-label {
        white-space: nowrap;
        visibility: hidden;
        height: 0;
    }
`;

const ExtendableChip = ({ label, title, icon, maxNbOfChar, style }: ExtendableChipProps) => {
    const id = useId();
    const [baseWidth, setBaseWidth] = useState<number>(0);
    const [textWidth, setTextWidth] = useState<number>(0);
    const [fullSize, setFullSize] = useState(false);
    const [element, setElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (element) {
            if (baseWidth === 0 && !fullSize) {
                setBaseWidth(element?.scrollWidth);
                setTextWidth(element?.scrollWidth);
            }
            if (label.length > maxNbOfChar) {
                setTextWidth(element?.scrollWidth);
            }
        } else setElement(document.getElementById(id));
    }, [id, fullSize, baseWidth, label, maxNbOfChar, element]);

    const labelToDisplay = fullSize ? label : truncateWithEllipsis(label, maxNbOfChar);

    return (
        <StyledBox width={baseWidth + 45}>
            <div className="fake-label" id={id}>
                {labelToDisplay}
            </div>
            {baseWidth > 0 ? <div className="absolute">
                <StyledChip
                    width={textWidth}
                    baseWidth={baseWidth}
                    onMouseEnter={() => setFullSize(true)}
                    onMouseLeave={() => setFullSize(false)}
                    title={title}
                    style={{ zIndex: 1, backgroundColor: '#E5E5E5', ...style }}>
                    {icon}
                    <div className="text">{labelToDisplay}</div>
                </StyledChip>
            </div> : <Skeleton sx={{width: "100px", height: "26px"}} />}
        </StyledBox>
    );
};

export default ExtendableChip;
