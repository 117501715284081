import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LinkContractsList from '../LinkContractsList';
import { PiFileXls } from 'react-icons/pi';
import ListPageWrapper from '../../Tools/ListPageWrapper';

const CreateLinkContractsSelection = () => {
    const [filterContracts, setFilterContracts] = useState<string[]>();
    const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
    const [listingIsVisible, setListingVisibility] = useState<boolean>(true);
    const navigate = useNavigate();

    const refreshListPage = () => {
        setListingVisibility(false);
        setTimeout(() => {
            setListingVisibility(true)
        })
    };

    return <>
        <LinkContractsList
            endpoint={`/api/racsup/primo/`}
            selectedContracts={selectedContracts}
            onFilterContracts={(contracts: string[]) => {
                setFilterContracts(contracts);
                refreshListPage()
            }}
            onCreateLink={() => {
                navigate("/racsup/create-link/" + selectedContracts.join(','));
            }}
        >
            {listingIsVisible &&
                <ListPageWrapper
                    name=""
                    endpoint={`/api/racsup/primo/`}
                    filters={`${filterContracts ? '&contract__in=' + filterContracts.join(',') : ''}`}
                    fields={[
                        {
                            name: "contract",
                            label: "Contrat",
                            orderable: true,
                        },
                        {
                            name: "contract_label",
                            label: "Libellé",
                            orderable: true,
                        },
                        {
                            name: "modules_count",
                            label: "Nb de module(s) raccordable(s)",
                            orderable: true,
                        },
                        {
                            name: "excluded_count",
                            label: "Nb de module(s) exclu(s)",
                            orderable: true,
                        },
                        {
                            name: "eligible_count",
                            label: "Nb total de module(s)",
                            orderable: true,
                        },
                        {
                            name: "repeaters_count",
                            label: "Nb de répéteur(s)",
                            orderable: true,
                        },
                        {
                            name: "concentrators_count",
                            label: "Nb de concentrateur(s)",
                            orderable: true,
                        },
                    ]}
                    exportButtons={[
                        {
                            name: "Exporter en xlsx",
                            fileName: "Export des contrats primo-raccordement(s).xlsx",
                            type: "xlsx",
                            icon: <PiFileXls />,
                            id: 'createdLinkContract-export-button'
                        },
                    ]}
                    paginationOptions={{ pageSize: 100 }}
                    onSelectItems={(selectedContracts: any[]) => {
                        setSelectedContracts(selectedContracts.map(selectedContract => selectedContract.contract))
                    }}

                />}
        </LinkContractsList></>
};


export default CreateLinkContractsSelection;