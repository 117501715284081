import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const EmptyPageWithLoader = ({className}:{className?:string}) => {
    return (
        <Box className={`w-100 h-100 d-flex align-items-center justify-content-center ${className ? className : ''}`} sx={{minHeight: 'calc(100vh - 350px)'}}>
            <CircularProgress color="inherit"/>
        </Box>
    );
};

export default EmptyPageWithLoader;
