import React, { useState } from "react";
import LinkContractsList from "../Racsup/LinkContractsList";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { PiFileXls } from "react-icons/pi";
import ListPageWrapper from "../Tools/ListPageWrapper";


export const OptimisationContractsSelection = () => {
    const [filterContracts, setFilterContracts] = useState<string[]>();
    const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
    const [listingIsVisible, setListingVisibility] = useState<boolean>(true);
    const navigate = useNavigate();

    const refreshListPage = () => {
        setListingVisibility(false);
        setTimeout(() => {
            setListingVisibility(true)
        })
    };

    return <>
        <LinkContractsList
            endpoint={`/api/optimisation/contracts/`}
            selectedContracts={selectedContracts}
            onFilterContracts={(contracts: string[]) => {
                setFilterContracts(contracts);
                refreshListPage()
            }}
            onCreateLink={() => {
                navigate("/optimisation/create/" + selectedContracts.join(','));
            }}
            formatResponse={(response: any) => {
                return {
                    label: `${response.label}`,
                    value: response.code,
                }
            }}
        >
            Données du {moment().startOf('isoWeek').format('DD/MM/YYYY').toString()}
            {listingIsVisible &&
                <ListPageWrapper
                    name=""
                    endpoint={`/api/optimisation/contracts/`}
                    filters={`${filterContracts ? '&code__in=' + filterContracts.join(',') : ''}`}
                    fields={[
                        {
                            name: "code",
                            label: "Contrat",
                            orderable: true
                        },
                        {
                            name: "label",
                            label: "Libellé",
                            orderable: true
                        },
                        {
                            name: "devices_count",
                            label: "Nb de module(s)",
                            orderable: true
                        },
                        {
                            name: "ok_devices_count",
                            label: "Nb de module(s) OK",
                            orderable: true
                        },
                        {
                            name: "nok_devices_count",
                            label: "Nb de module(s) NOK",
                            orderable: true
                        },
                        {
                            name: 'abs_devices_count',
                            label: 'Nb de module(s) ABS',
                            orderable: true
                        },
                        {
                            name: "potential_improvement_devices_count",
                            label: "Nb de module(s) améliorable(s)",
                            orderable: true
                        },
                        {
                            name: 'current_performance_average',
                            label: 'Performance actuelle',
                            transform: (perf: number) => {
                                return (perf * 100).toFixed(2) + '%';
                            },
                            orderable: true
                        },
                        {
                            name: 'potential_performance_average',
                            label: 'Performance potentielle',
                            transform: (perf: number) => {
                                return (perf * 100).toFixed(2) + '%';
                            },
                            orderable: true
                        }
                    ]}
                    displayPaginationOptions={true}
                    onSelectItems={(selectedContracts: any[]) => {
                        setSelectedContracts(selectedContracts.map(selectedContract => selectedContract.code))
                    }}
                    exportButtons={[
                        {
                            name: "Exporter en xlsx",
                            fileName: "Optimisations.xlsx",
                            type: "xlsx",
                            icon: <PiFileXls />,
                            id:'optimisation-export-button'

                        }
                    ]}
                />}
        </LinkContractsList></>
};


export default OptimisationContractsSelection;
