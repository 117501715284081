import React from "react";
import { useDialog, BirdzDialog, Item, FormField, StyledTitle, AppPaper, useNotif, BirdzNotif } from '@applications-terrains/birdz-react-library';
import { Link } from "react-router-dom";
import moment from "moment";
import { Protocol as ProtocolType } from "../types";
import Axios from "axios";
import { Button, Grid, IconButton } from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { authService } from "../..";
import ListPageWrapper from "../Tools/ListPageWrapper";


interface ProtocolProps {
  action: "list" | "add" | "edit";
}

const Protocol = ({ action }: ProtocolProps) => {
  const endpoint = "/api/protocols/";
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  let formFields: FormField[] = [
    { name: "name", type: "text", label: "Nom" },
    {
      name: "technical_code",
      type: "text",
      label: "Code technique",
    },
    {
      name: "operator_code",
      type: "text",
      label: "Code opérateur",
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, label: "Actif" },
          { value: false, label: "Inactif" },
        ],
      },
      defaultValue: true,
      permissions: ["IS_ACTIVE"],
    },
    {
      name: "updated_at",
      type: "readonly",
      label: "Dernière modification",
      transform: (value: any) => {
        let date =
          value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];
  let listFields = [
    { name: "name", label: "Nom", orderable: true },
    {
      name: "technical_code",
      label: "Code protocole",
      options: { width: "180px" },
      orderable: true,
    },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      options: { width: "100px" },
      orderable: true,
    },
    {
      name: "updated_at",
      label: "Date de modification",
      options: { width: "220px" },
      orderable: true,
      transform: (value: any) => {
        return (
          (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
        );
      },
    },
  ];
  let searchFields = [
    {
      name: "name",
      label: "Nom protocol",
      options: {
        identifier: "name",
        source: "/api/protocols/",
        label: "name",
        searchIsMulti: false,
      },
    },
  ];
  let actions = [
    {
      name: "edit",
      label: "Modifier le protocole",
      render: (protocol: ProtocolType) => {
        return <IconButton id={'protocol-edit-button'} component={Link} to={`/models/parameters/protocols/edit/${protocol.id}`}>
          <Edit fontSize="small" />
        </IconButton>
      }
    },
    {
      name: "delete-protocol",
      render: (protocol: ProtocolType) => {
        return <IconButton id={'protocol-delete-button'} onClick={() => {
          confirmDialog({
            title: "Supprimer le protocole",
            content: "Êtes-vous sûr de vouloir supprimer ce protocole?",
            onValidate: () => {
              Axios.delete(`${endpoint}${protocol.id}/`).then(
                () => {
                  notif({
                    content: "Le protocole a été supprimé avec succès",
                    type: "success",
                  });
                  document.location.reload();
                },
                () => {
                  notif({
                    content: "Une erreur est survenue lors de la suppression",
                    type: "error",
                  });
                }
              )
              closeDialog();
            },
            onCancel: () => {
              closeDialog();
            }
          })
        }}><Delete fontSize="small" /></IconButton>
      }
    },
  ];

  return (
    <AppPaper>
      {action === "list" && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <StyledTitle>Liste des protocoles</StyledTitle>
            </Grid>
            {authService.canAccess("WRITE_PARAMETERS") && (
              <Grid item>
                <Link to="/models/parameters/protocols/add">
                  <Button  id={'protocol-add-button'} variant="contained" sx={{ mt: 3 }}>
                    <AddCircle sx={{ mr: 1 }} /> Ajouter un protocole
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>

          <ListPageWrapper
            endpoint={endpoint}
            fields={listFields}
            actions={authService.canAccess("WRITE_PARAMETERS") ? actions : []}
            searchFields={searchFields}
            defaultOrder={['name']}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un protocole"
        />
      )}
      {action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier le protocole"
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}

export default Protocol;