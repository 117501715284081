import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import dataService from '../Common/Services/dataService';
import { Form, Formik, FormikHelpers } from 'formik';
import { Protocol } from '../types';
import {
    AppPaper,
    BirdzNotif,
    StyledTitle,
    useNotif
} from '@applications-terrains/birdz-react-library';
import { providers } from './Operators';
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import * as Yup from 'yup';

type ParamTypes = {
    id: string;
};

export const OperatorsForm = (params: any) => {
    const { id } = useParams<ParamTypes>();
    const navigate = useNavigate();
    const { notif, notifOptions } = useNotif();

    const [operator, setOperator] = useState<any>({
        id: null,
        name: '',
        protocol: null,
        type: null,
        technical_code: '',
        technical_address: '',
        technical_user: '',
        technical_token_api: '',
        technical_password: '',
        handle_ack: false,
        retry_limit: null,
        retry_timeout: null,
        rate_limit: '',
        provider: ''
    });

    const [disabledFields, setDisabledFields] = useState<string[]>([]);

    const technicalTypes = [
        { value: '', label: '' },
        { value: 'api', label: 'API' },
        { value: 'ftp', label: 'FTP' }
    ];

    const technicalCodes = [
        { value: '', label: '' },
        { value: 'ACT', label: 'ACT' },
        { value: 'BIR', label: 'BIR' },
        { value: 'LVO', label: 'LVO' },
        { value: 'MOCK', label: 'MOCK' },
        { value: 'OBS', label: 'OBS' },
        { value: 'OBJ', label: 'OBJ' }
    ];

    const protocols = (dataService.getData('protocols') as Protocol[]).map((protocol) => {
        return { label: protocol.name, value: protocol.id };
    });

    const handleTechnicalCode = (technicalCode: string) => {
        let newProtocol: any = '';
        let newType: any = '';
        let newDisabledFields: string[] = [];

        switch (technicalCode) {
            case 'BIR':
                newProtocol = 'Homerider';
                newType = 'ftp';
                newDisabledFields = [
                    'technical_user',
                    'technical_password',
                    'technical_token_api',
                    'retry_limit',
                    'rate_limit'
                ];
                break;
            case 'OBS':
                newProtocol = 'Homerider';
                newType = 'ftp';
                newDisabledFields = [
                    'technical_password',
                    'technical_token_api',
                    'retry_limit',
                    'rate_limit'
                ];
                break;
            case 'LVO':
                newProtocol = 'LoRaWAN';
                newType = 'api';
                newDisabledFields = ['technical_user', 'technical_password'];
                break;
            case 'OBJ':
                newProtocol = 'LoRaWAN';
                newType = 'api';
                newDisabledFields = [
                    'technical_user',
                    'technical_password'
                    // 'retry_limit'
                ];
                break;
            case 'ACT':
                newProtocol = 'LoRaWAN';
                newType = 'api';
                newDisabledFields = [
                    // 'retry_limit'
                ];
                break;
            case 'MOCK':
                newProtocol = 'Homerider';
                newType = 'ftp';
                newDisabledFields = [
                    'technical_user',
                    'technical_password',
                    'technical_token_api',
                    'retry_limit',
                    'rate_limit',
                    'technical_address'
                ];
                break;
        }

        if (newProtocol) {
            newProtocol = protocols.find((protocol) => newProtocol === protocol.label);
        }
        if (newType) {
            newType = technicalTypes.find((technicalType) => newType === technicalType.value);
        }

        setDisabledFields(newDisabledFields);
        return {
            protocol: newProtocol,
            type: newType,
            technical_code: technicalCode
        };
    };

    useEffect(() => {
        if (params.action === 'edit' && id && !operator.id) {
            axios.get('/api/operators/' + id + '/').then((response) => {
                setOperator(response.data);
            });
        }
        //eslint-disable-next-line
    }, [params, id, technicalCodes, technicalTypes, protocols]);

    const saveOperator = (values: any) => {
        const body = values;
        let savePromise: Promise<any>;
        body.retry_limit = body.retry_limit === '' ? null : body.retry_limit;
        body.retry_timeout = body.retry_timeout === '' ? null : body.retry_timeout;

        // not send disabled fields
        disabledFields.forEach((disabledField) => {
            delete body[disabledField];
        });

        if (values.id) {
            savePromise = axios.put('/api/operators/' + values.id + '/', body);
        } else {
            savePromise = axios.post('/api/operators/', body);
        }
        savePromise.then(() => {
            notif({
                title: 'Succès',
                content: values?.id
                    ? 'Enregistrement modifié avec succès'
                    : 'Enregistrement créé avec succès',
                type: 'success'
            });
            setTimeout(() => {
                navigate('/admin/operators');
            }, 2000);
        });
    };

    return (
        <AppPaper>
            <StyledTitle>
                {operator.id
                    ? 'Modifier une fiche technique (opérateur)'
                    : 'Ajouter une fiche technique (opérateur)'}
            </StyledTitle>

            <Formik
                initialValues={operator}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
                    //eslint-disable-line
                    saveOperator(values);
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required('Vous devez renseigner le nom'),
                    protocol: Yup.string().required('Vous devez choisir un protocole').nullable(),
                    provider: Yup.string().required('Vous devez choisir un provider'),
                    technical_code: Yup.string().required('Vous devez renseigner un code'),
                    rate_limit: Yup.number()
                        .typeError('Un nombre entier valide est requis.')
                        .min(1, 'Un nombre entier valide est requis.')
                        .required('Un nombre entier valide est requis.'),
                    type: Yup.string()
                        .required('Vous devez choisir au moins un device type')
                        .nullable(),
                    handle_ack: Yup.bool(),
                    id: Yup.string().nullable(),

                    retry_limit: Yup.number().nullable(),
                    retry_timeout: Yup.number().nullable(),
                    technical_address: Yup.string(),

                    technical_password: Yup.string(),
                    technical_token_api: Yup.string(),
                    technical_user: Yup.string()
                })}>
                {({
                    values,
                    getFieldProps,
                    setFieldValue,
                    // isValid,
                    // dirty,
                    errors,
                    validateForm,
                    touched,
                    isSubmitting
                }) => {
                    return (
                        <Form>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid alignItems={'center'} item xs={2}>
                                    Nom *
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-name-input'}
                                        type={'text'}
                                        size="small"
                                        fullWidth
                                        {...getFieldProps('name')}
                                    />
                                    {(touched.name || isSubmitting) &&
                                        typeof errors.name !== 'undefined' && (
                                            <Typography sx={{ color: 'red' }}>
                                                {errors.name as ReactNode}
                                            </Typography>
                                        )}
                                </Grid>
                                <Grid alignItems={'center'} item xs={2}>
                                    Code opérateur Firestore *
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-technical_code-input'}
                                        type={'text'}
                                        size="small"
                                        fullWidth
                                        {...getFieldProps('technical_code')}
                                    />
                                    {(touched.technical_code || isSubmitting) &&
                                        typeof errors.technical_code !== 'undefined' && (
                                            <Typography sx={{ color: 'red' }}>
                                                {errors.technical_code as ReactNode}
                                            </Typography>
                                        )}
                                </Grid>
                                <Grid alignItems={'center'} item xs={2}>
                                    Provider *
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-provider-input'}
                                        select
                                        sx={{
                                            minWidth: 300
                                        }}
                                        size="small"
                                        {...getFieldProps('provider')}
                                        onChange={(e: any) => {
                                            const provider = e?.target?.value;
                                            const { protocol, type } =
                                                handleTechnicalCode(provider);
                                            setFieldValue('provider', provider);
                                            setFieldValue('protocol', protocol.value);
                                            setFieldValue('type', type.value);
                                        }}
                                        value={values['provider'] || ''}>
                                        <MenuItem key={'provider-empty'} value={''} />
                                        {providers.map((provider: any) => (
                                            <MenuItem
                                                key={`provider-${provider.value}`}
                                                value={provider.value}>
                                                {provider.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {(touched.provider || isSubmitting) &&
                                        typeof errors.provider !== 'undefined' && (
                                            <Typography sx={{ color: 'red' }}>
                                                {errors.provider as ReactNode}
                                            </Typography>
                                        )}
                                </Grid>

                                <Grid alignItems={'center'} item xs={2}>
                                    Type *
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-type-input'}
                                        select
                                        sx={{
                                            minWidth: 300
                                        }}
                                        size="small"
                                        {...getFieldProps('type')}
                                        value={values['type'] || ''}>
                                        <MenuItem key={'type-empty'} value={''} />
                                        {technicalTypes.map((technicalType: any) => (
                                            <MenuItem
                                                key={`technicalType-${technicalType.value}`}
                                                value={technicalType.value}>
                                                {technicalType.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {(touched.type || isSubmitting) &&
                                        typeof errors.type !== 'undefined' && (
                                            <Typography sx={{ color: 'red' }}>
                                                {errors.type as ReactNode}
                                            </Typography>
                                        )}
                                </Grid>

                                <Grid alignItems={'center'} item xs={2}>
                                    Protocole *
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-protocol-input'}
                                        select
                                        sx={{
                                            minWidth: 300
                                        }}
                                        size="small"
                                        {...getFieldProps('protocol')}
                                        value={values['protocol'] || ''}>
                                        <MenuItem key={'protocol-empty'} value={''} />
                                        {protocols.map((protocol: any) => (
                                            <MenuItem
                                                key={`technicalType-${protocol.value}`}
                                                value={protocol.value}>
                                                {protocol.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {(touched.protocol || isSubmitting) &&
                                        typeof errors.protocol !== 'undefined' && (
                                            <Typography sx={{ color: 'red' }}>
                                                {errors.protocol as ReactNode}
                                            </Typography>
                                        )}
                                </Grid>

                                <Grid alignItems={'center'} item xs={2}>
                                    Adresse
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-address-input'}
                                        type={'text'}
                                        size="small"
                                        fullWidth
                                        {...getFieldProps('technical_address')}
                                        disabled={disabledFields.includes('technical_address')}
                                    />
                                </Grid>

                                <Grid alignItems={'center'} item xs={2}>
                                    Identifiant
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-technical_user-input'}
                                        type={'text'}
                                        size="small"
                                        fullWidth
                                        {...getFieldProps('technical_user')}
                                        disabled={disabledFields.includes('technical_user')}
                                    />
                                </Grid>
                                <Grid alignItems={'center'} item xs={2}>
                                    Mot de passe
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-password-input'}
                                        type={'password'}
                                        size="small"
                                        fullWidth
                                        {...getFieldProps('technical_password')}
                                        disabled={disabledFields.includes('technical_password')}
                                    />
                                </Grid>

                                <Grid alignItems={'center'} item xs={2}>
                                    Token technique
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-technical_token_api-input'}
                                        type={'text'}
                                        size="small"
                                        fullWidth
                                        {...getFieldProps('technical_token_api')}
                                        disabled={disabledFields.includes('technical_token_api')}
                                    />
                                </Grid>

                                <Grid alignItems={'center'} item xs={2}>
                                    Gestion ACK
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <RadioGroup
                                        id={'operator-form-handle_ack-radio-group'}
                                        row
                                        {...getFieldProps('handle_ack')}
                                        onChange={(e) => {
                                            setFieldValue('handle_ack', e.target.value === 'true');
                                        }}>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio />}
                                            label="Oui"
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio />}
                                            label="Non"
                                        />
                                    </RadioGroup>
                                </Grid>

                                {
                                    // enable only if protocol is 'LoRaWan'
                                    values.protocol === 3 && (
                                        <>
                                            <Grid alignItems={'center'} item xs={2}>
                                                Retry limit
                                            </Grid>
                                            <Grid alignItems={'center'} item xs={10}>
                                                <TextField
                                                    id={'operator-form-retry_limit-input'}
                                                    type={'number'}
                                                    size="small"
                                                    fullWidth
                                                    {...getFieldProps('retry_limit')}
                                                    disabled={disabledFields.includes(
                                                        'retry_limit'
                                                    )}
                                                    value={values['retry_limit'] || ''}
                                                />
                                            </Grid>
                                        </>
                                    )
                                }

                                <Grid alignItems={'center'} item xs={2}>
                                    Retry timeout
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-retry_timeout-input'}
                                        type={'number'}
                                        size="small"
                                        fullWidth
                                        {...getFieldProps('retry_timeout')}
                                        value={values['retry_timeout'] || ''}
                                    />
                                </Grid>

                                <Grid alignItems={'center'} item xs={2}>
                                    Rate limit *
                                </Grid>
                                <Grid alignItems={'center'} item xs={10}>
                                    <TextField
                                        id={'operator-form-rate_limit-input'}
                                        type={'number'}
                                        size="small"
                                        fullWidth
                                        {...getFieldProps('rate_limit')}
                                        disabled={disabledFields.includes('rate_limit')}
                                        value={values['rate_limit'] || ''}
                                    />
                                    {(touched.rate_limit || isSubmitting) &&
                                        typeof errors.rate_limit !== 'undefined' && (
                                            <Typography sx={{ color: 'red' }}>
                                                {errors.rate_limit as ReactNode}
                                            </Typography>
                                        )}
                                </Grid>

                                {operator && operator.id && (
                                    <>
                                        <Grid alignItems={'center'} item xs={2}>
                                            État
                                        </Grid>
                                        <Grid alignItems={'center'} item xs={10}>
                                            <RadioGroup
                                                id={'operator-form-is_active-radio-group'}
                                                row
                                                {...getFieldProps('is_active')}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'is_active',
                                                        e.target.value === 'true'
                                                    );
                                                }}
                                                value={values['is_active'] === true}>
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio />}
                                                    label="Actif"
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio />}
                                                    label="Inactif"
                                                />
                                            </RadioGroup>
                                        </Grid>

                                        <Grid alignItems={'center'} item xs={2}>
                                            Dernière modification
                                        </Grid>
                                        <Grid alignItems={'center'} item xs={10}>
                                            {moment
                                                .unix(operator.updated_at)
                                                .format('DD/MM/YYYY à HH:mm:ss')}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Box textAlign="center" sx={{ mt: 2 }}>
                                <Button
                                    id={'operator-form-submit-button'}
                                    variant="contained"
                                    type="submit"
                                    // disabled={!dirty || !isValid}
                                    onClick={() =>
                                        validateForm().then((errors: any) => {
                                            const errorsList: string[] = Object.values(errors);
                                            if (errorsList.length) {
                                                notif({
                                                    type: 'error',
                                                    content: (
                                                        <div>
                                                            <>
                                                                {
                                                                    'Veuillez corriger les erreurs sur le formulaire :'
                                                                }
                                                            </>
                                                            <ul>
                                                                {errorsList.map((error) => (
                                                                    <li key={error}>{error}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )
                                                });
                                            }
                                        })
                                    }>
                                    Enregistrer
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
            <BirdzNotif options={notifOptions} />
        </AppPaper>
    );
};

export default OperatorsForm;
