import React from 'react';
import { Trans } from 'react-i18next';
import { formatData } from './ParametersUtils';
import { Checkbox, FormControlLabel } from '@mui/material';

type ParametersCheckboxProps = {
    field: any;
    value: any[];
    onFieldChange: any;
    id?: string;
    disabled: boolean;
};
export const ParametersCheckbox = ({
    field,
    value,
    onFieldChange,
    id,
    disabled
}: ParametersCheckboxProps) => {
    return (
        <>
            {Object.keys(field.possible_value).map((val: any) => {
                let possibleValueKey = '' + field.possible_value[val.toString()];
                let possibleValueName = val;

                let label = possibleValueName ? <Trans>{possibleValueName}</Trans> : '';
                let uniqueId = id ? id + '-' + field.name : field.name;

                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={'parameter-checkbox'}
                                key={uniqueId}
                                name={uniqueId}
                                onChange={(e: any) => {
                                    let targetValue = e.target.value;
                                    let newValue: any[] = [];

                                    // if value not empty
                                    if (value && Array.isArray(value)) {
                                        let checkedValue = formatData(field.type, targetValue);
                                        // if already existing, delete it
                                        if (value.includes(checkedValue)) {
                                            newValue = value.filter((val) => val !== checkedValue);
                                        }
                                        // else add it
                                        else {
                                            newValue = [...value, checkedValue];
                                        }
                                    } else {
                                        newValue = [formatData(field.type, targetValue)];
                                    }

                                    onFieldChange(field.id, newValue);
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked={
                                    value &&
                                    Array.isArray(value) &&
                                    value.includes(possibleValueKey)
                                        ? true
                                        : false
                                }
                                value={possibleValueKey}
                            />
                        }
                        label={label}
                        sx={{
                            '& .MuiSvgIcon-root': { fontSize: 18 },
                            '& .MuiFormControlLabel-label': { fontSize: '15px' }
                        }}
                        id={uniqueId}
                        disabled={disabled}
                    />
                );
            })}
        </>
    );
};
