import React, { CSSProperties, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import axios from 'axios';

export const Loader = () => {
    const override: CSSProperties = {
        display: 'block',
        position: 'fixed',
        left: '0px',
        right: '0px',
        bottom: '0px',
        margin: '0 auto',
        width: '100%',
        zIndex: 2000
    };

    const [isLoading, setIsLoading] = useState(false);

    const disableLoaderForThesesRoutes = [''];

    const setInteceptors = () => {
        axios.interceptors.response.use(
            (response: any) => {
                // Do something with response data
                setIsLoading(false);
                return response;
            },
            async (error) => {
                setIsLoading(false);
                // Trow errr again (may be need for some other catch)
                return Promise.reject(error);
            }
        );

        axios.interceptors.request.use((config) => {
            let loaderIsDisabledForThisUrl = false;
            disableLoaderForThesesRoutes.forEach((route: string) => {
                if (route && config.url?.startsWith(route)) {
                    loaderIsDisabledForThisUrl = true;
                }
            });

            if (!loaderIsDisabledForThisUrl) {
                setIsLoading(true);
            }

            return config;
        });
    };
    setInteceptors();

    return <BarLoader height={6} color={'#253053'} cssOverride={override} loading={isLoading} />;
};
