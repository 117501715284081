import React from 'react';
import { formatData } from './ParametersUtils';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

type ParametersRadioProps = {
    field: any;
    value: any;
    onFieldChange: any;
    id?: string;
    disabled: boolean;
};
export const ParametersRadio = ({
    field,
    value,
    onFieldChange,
    id,
    disabled
}: ParametersRadioProps) => {
    return (
        <RadioGroup
            row
            onChange={(e: any) => {
                onFieldChange(field.id, formatData(field.type, e.target.value));
            }}
            defaultValue={value}
            sx={{ ml: 1 }}>
            {Object.keys(field.possible_value).map((val: any, index: number) => {
                let possibleValueKey = '' + field.possible_value[val.toString()];
                let possibleValueName = val;
                const uniqueId = id ? id + '-' + index : index + '';
                return (
                    <FormControlLabel
                        key={uniqueId}
                        id={uniqueId}
                        disabled={disabled}
                        label={possibleValueName}
                        value={possibleValueKey}
                        control={<Radio id={'parameter-radio'} />}
                        sx={{
                            '& .MuiSvgIcon-root': { fontSize: 18 },
                            '& .MuiFormControlLabel-label': { fontSize: '15px' }
                        }}
                    />
                );
            })}
        </RadioGroup>
    );
};
