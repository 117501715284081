import { FC } from 'react';
import { CampaignContextProvider } from './CampaignContext';
import { FetchContextProvider } from './FetchContext';
import { ResetFormContextProvider } from './ResetFormContext';

export type Children = {
    children?: React.ReactNode;
};

const AppContextProvider: FC<Children> = ({ children }) => {
    return (
        <FetchContextProvider>
            <CampaignContextProvider>
                <ResetFormContextProvider>{children}</ResetFormContextProvider>
            </CampaignContextProvider>
        </FetchContextProvider>
    );
};

export default AppContextProvider;
