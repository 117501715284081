import { ReactNode, createContext, useContext, useState } from 'react';

type FetchContextType = {
    dataRefs: any,
    setDataRefs: any
};

const FetchContext = createContext<FetchContextType | undefined>(undefined);

export const useFetchContext = () => {
    const context = useContext(FetchContext);

    if (context === undefined) {
        throw new Error('useFetchContext must be used with a FetchContext');
    }

    return context;
};

export const FetchContextProvider = ({ children }: { children: ReactNode }) => {

    const [dataRefs, setDataRefs] = useState<{
        [key: string]: {
            count: number;
            endpoint: string;
            next?: string | null;
            previous?: string | null;
            results: object[];
        };
    }>({});

    const value = {
        dataRefs,
        setDataRefs
    };

    return (
        <FetchContext.Provider value={value}>
            {children}
        </FetchContext.Provider>
    );
};
