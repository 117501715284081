import dataService from '../../Common/Services/dataService';
import {
    FormField,
    Item,
    BirdzDialog,
    useDialog,
    AppPaper,
    StyledTitle,
    BirdzNotif,
    useNotif
} from '@applications-terrains/birdz-react-library';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Protocol } from '../../types';
import Axios from 'axios';
import { authService } from '../../..';
import { Button, Grid, IconButton } from '@mui/material';
import { AddCircle, Delete, Edit } from '@mui/icons-material';
import ListPageWrapper from '../../Tools/ListPageWrapper';

interface Props {
    action: 'list' | 'add' | 'edit';
}

export const Configuration = ({ action }: Props) => {
    const endpoint = '/api/racsup/instruction-configurations/';
    const navigate = useNavigate();
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();

    const transformItemOnSave = (item: any) => {
        item.protocol = item?.protocol?.value || '';
        item.template = item?.template?.value || '';
        return item;
    };

    const transformItemOnLoad = (item: any) => {
        if (item.protocol) {
            const protocol = (dataService.getData('protocols') as Protocol[]).find((protocol) => {
                return protocol.id === item.protocol;
            });
            item.protocol = {
                value: protocol?.id,
                label: protocol?.name
            };
        }
        if (item.template) {
            item.template = { value: item.template, label: item.template__name };
        }
        return item;
    };

    const formFields: FormField[] = [
        {
            name: 'type',
            type: 'select',
            label: 'Type',
            options: {
                values: [
                    { value: 'PRIMO', label: 'Primo raccordement' },
                    { value: 'RERAC', label: 'Re-raccordement' },
                    { value: 'RENFO', label: 'Renforcement' },
                    { value: 'NET', label: 'Nettoyage' },
                    { value: 'EVIC', label: 'Éviction' }
                ]
            }
        },
        {
            name: 'protocol',
            type: 'autocomplete',
            label: 'Protocole',
            options: {
                source: '/api/lists/protocols/?ordering=name&',
                searchIsMulti: false
            }
        },
        {
            name: 'template',
            type: 'autocomplete',
            label: 'Template',
            options: {
                source: '/api/frame-templates/',
                searchIsMulti: false
            }
        }
    ];

    const listFields = [
        {
            name: 'type',
            label: 'Type',
            orderable: true
        },
        {
            name: 'template__name',
            label: 'Template',
            orderable: true
        },
        {
            name: 'protocol',
            label: 'Protocole',
            orderable: true,
            transform: (value: number) => {
                let dataServiceProtocol = dataService.getData('protocols') as Protocol[];
                let protocol = dataServiceProtocol.find((protocol: any) => protocol.id === value);
                return protocol?.name || value;
            }
        },
        {
            name: 'created_at',
            label: 'Date de création',
            options: { width: '215px' },
            orderable: true,
            transform: (value: any) => {
                return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            }
        },
        {
            name: 'updated_at',
            label: 'Date de modification',
            options: { width: '215px' },
            orderable: true,
            transform: (value: any) => {
                return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            }
        }
    ];

    const actions = [
        {
            name: 'edit',
            label: 'Modifier la configuration',
            render: (value: any) => (
                <IconButton
                    id={'racsupConfiguration-edit-button'}
                    onClick={() => {
                        navigate(`/racsup/configuration/edit/${value.id}`);
                    }}>
                    <Edit fontSize="small" />
                </IconButton>
            )
        },
        {
            name: 'delete-config',
            label: 'Supprimer la configuration',
            render: (config: any) => {
                return (
                    <IconButton
                        id={'racsupConfiguration-delete-button'}
                        onClick={() => {
                            confirmDialog({
                                title: 'Supprimer la configuration',
                                content: 'Êtes-vous sûr de vouloir supprimer cette configuration?',
                                onValidate: () => {
                                    Axios.delete(`${endpoint}${config.id}/`).then(
                                        () => {
                                            notif({
                                                content:
                                                    'Êtes-vous sûr de vouloir supprimer cette configuration?',
                                                type: 'success'
                                            });
                                            document.location.reload();
                                        },
                                        () => {
                                            notif({
                                                content:
                                                    'Une erreur est survenue lors de la suppression',
                                                type: 'error'
                                            });
                                        }
                                    );
                                    closeDialog();
                                },
                                onCancel: () => {
                                    closeDialog();
                                }
                            });
                        }}>
                        <Delete fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    return (
        <AppPaper>
            {action === 'list' && (
                <>
                    <Grid justifyContent="space-between" container>
                        <Grid item>
                            <StyledTitle>Configuration instructions</StyledTitle>
                        </Grid>
                        {authService.canAccess('WRITE_PARAMETERS') && (
                            <Grid item>
                                <Link to="/racsup/configuration/add">
                                    <Button
                                        id={'racsupConfiguration-add-button'}
                                        variant="contained"
                                        sx={{ mt: 3 }}>
                                        <AddCircle sx={{ mr: 1 }} /> Ajouter une configuration
                                    </Button>
                                </Link>
                            </Grid>
                        )}
                    </Grid>

                    <ListPageWrapper
                        endpoint={endpoint}
                        fields={listFields}
                        actions={authService.canAccess('WRITE_PARAMETERS') ? actions : []}
                        paginationOptions={{ pageSize: 1000 }}
                    />
                    <BirdzDialog options={dialogOptions} />
                </>
            )}
            {action === 'add' && (
                <Item
                    action="add"
                    endpoint={endpoint}
                    fields={formFields}
                    name="Ajouter une configuration"
                    transformItemOnSave={(item: any) => transformItemOnSave(item)}
                    transformItemOnLoad={(item: any) => transformItemOnLoad(item)}
                />
            )}
            {action === 'edit' && (
                <Item
                    action="edit"
                    endpoint={endpoint}
                    fields={formFields}
                    name="Modifier la configuration"
                    transformItemOnSave={(item: any) => transformItemOnSave(item)}
                    transformItemOnLoad={(item: any) => transformItemOnLoad(item)}
                />
            )}
            <BirdzNotif options={notifOptions} />
        </AppPaper>
    );
};

export default Configuration;
