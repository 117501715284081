import { useEffect, useState } from 'react';
import EligibleModulesSearch, { EligibleModulesFilters } from './EligibleModulesSearch';
import SortItems, { Sort } from './SortItems';
import './EligibleModules.scss';
import _ from 'lodash';
import dataService from '../Common/Services/dataService';
import { EligibleModulesType, SelectedModules } from './LinkForm';
import {
    Alert,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead
} from '@mui/material';
import {
    StyledTableBodyRow,
    StyledTableHeaderRow
} from '@applications-terrains/birdz-react-library';

export type EligibleModule = {
    commune: string;
    module_address: string;
    device_type: number;
    concentrator: string;
    repeater: string;
    repeater_device_type: number;
};

type EligibleModulesProps = {
    contract: string;
    allEligibleModulesByContract: EligibleModulesType;
    allSelectedModulesByContract: SelectedModules;
    onSelectModules(modules: EligibleModule[]): void;
    onFilterModules(filtersModules: EligibleModulesFilters): void;
    filtersModules: EligibleModulesFilters;
};

export default function EligibleModules({
    allEligibleModulesByContract,
    onSelectModules,
    allSelectedModulesByContract,
    onFilterModules,
    filtersModules,
    contract
}: EligibleModulesProps) {
    const [modules, setModules] = useState<EligibleModule[]>(
        allEligibleModulesByContract[contract]
    );
    const [selectedModules, setSelectedModules] = useState<EligibleModule[]>(
        allSelectedModulesByContract[contract] || []
    );
    const [sort, setSort] = useState<Sort | null>();

    useEffect(() => {
        onSelectModules(selectedModules);
    }, [onSelectModules, selectedModules]);

    useEffect(() => {
        const sortField = sort?.field;
        const sortWay = sort?.way;

        if (!sortField) {
            return;
        }

        setModules((modules) => {
            return [...modules].sort((a: any, b: any) => {
                return a[sortField].toLowerCase() > b[sortField].toLowerCase()
                    ? sortWay === 'asc'
                        ? 1
                        : -1
                    : sortWay === 'asc'
                      ? -1
                      : 1;
            });
        });
    }, [sort]);

    useEffect(() => {
        let newModules = [...allEligibleModulesByContract[contract]];
        if (filtersModules.communes && filtersModules.communes.length) {
            const communesIds = filtersModules.communes.map(
                (selectedCommune: any) => selectedCommune.value
            );
            newModules = newModules.filter((module: EligibleModule) => {
                return communesIds.includes(module.commune);
            });
        }

        if (filtersModules?.modules_deviceTypes && filtersModules.modules_deviceTypes.length) {
            const modulesDeviceTypes = filtersModules.modules_deviceTypes.map(
                (selectedModule: any) => selectedModule.value
            );
            newModules = newModules.filter((module: EligibleModule) => {
                return modulesDeviceTypes.includes(module.device_type);
            });
        }

        if (filtersModules?.repeaters_deviceTypes && filtersModules.repeaters_deviceTypes.length) {
            const repeatersDeviceTypes = filtersModules.repeaters_deviceTypes.map(
                (selectedRepeater: any) => selectedRepeater.value
            );
            newModules = newModules.filter((module: EligibleModule) => {
                return repeatersDeviceTypes.includes(module.repeater_device_type);
            });
        }

        if (newModules.length !== modules.length) {
            setModules(newModules);
            setSelectedModules(newModules);
        }
    }, [filtersModules, modules, allEligibleModulesByContract, contract]);

    const selectModule = (module: EligibleModule) => {
        // if device is selected, remove it from selected devices
        if (isModuleSelected(module)) {
            setSelectedModules(
                selectedModules?.filter(
                    (selectedModule) => selectedModule.module_address !== module.module_address
                )
            );
        } else {
            setSelectedModules([...selectedModules, module]);
        }
    };

    const getAllSelectedModules = () => {
        return _.flatMap(
            Object.keys(allSelectedModulesByContract).map((contract: string) => {
                return allSelectedModulesByContract[contract] || [];
            })
        );
    };

    const isModuleSelected = (module: EligibleModule) => {
        if (!selectedModules || !selectedModules.length) {
            return false;
        }

        return (
            selectedModules.find(
                (selectedModule) => selectedModule?.module_address === module?.module_address
            ) !== undefined
        );
    };

    const selectAllModules = () => {
        if (allModulesAreChecked()) {
            setSelectedModules([]);
        } else {
            setSelectedModules(modules);
        }
    };

    const allModulesAreChecked = () => {
        return _.isEqual(selectedModules, modules);
    };

    const displaySelectedModules = () => {
        const allSelectedModulesCount = selectedModules.length;
        const allModules = modules.length;

        return (
            <>
                {allSelectedModulesCount}/{allModules} module{allSelectedModulesCount > 1 && 's'}{' '}
                sélectionné{allSelectedModulesCount > 1 && 's'}
            </>
        );
    };

    return (
        <>
            <EligibleModulesSearch
                modules={modules}
                onFilter={(filtersModules) => {
                    onFilterModules(filtersModules);
                }}
                filtersModules={filtersModules}
            />

            <div className="info">
                <h5>{displaySelectedModules()}</h5>
                {getAllSelectedModules().length === 0 && (
                    <Alert severity="error">
                        Attention, vous devez choisir au moins un module.
                    </Alert>
                )}
            </div>

            <TableContainer component={Paper}>
                <Table className="eligibleModules" size="small">
                    <TableHead>
                        <StyledTableHeaderRow className="header">
                            <TableCell>
                                <input
                                    id={'eligibleModules-selectAll-checkbox'}
                                    type="checkbox"
                                    onChange={() => selectAllModules()}
                                    checked={allModulesAreChecked()}
                                />
                            </TableCell>
                            <SortItems
                                field="commune"
                                label="Commune"
                                onSort={(newSort: Sort) => setSort(newSort)}
                                currentSort={sort}
                            />
                            <SortItems
                                field="module_address"
                                label="Module"
                                onSort={(newSort: Sort) => setSort(newSort)}
                                currentSort={sort}
                            />
                            <SortItems
                                field="concentrator"
                                label="Concentrateur"
                                onSort={(newSort: Sort) => setSort(newSort)}
                                currentSort={sort}
                            />
                            <SortItems
                                field="repeater"
                                label="Répéteur"
                                onSort={(newSort: Sort) => setSort(newSort)}
                                currentSort={sort}
                            />
                        </StyledTableHeaderRow>
                    </TableHead>
                    <TableBody>
                        {modules?.map((eligibleModule: EligibleModule) => {
                            return (
                                <StyledTableBodyRow
                                    key={
                                        eligibleModule.module_address +
                                        eligibleModule.device_type +
                                        eligibleModule.concentrator +
                                        eligibleModule.repeater
                                    }
                                    onClick={() => selectModule(eligibleModule)}>
                                    <TableCell>
                                        <input
                                            id={'eligibleModule-selectModule-checkbox'}
                                            type="checkbox"
                                            onChange={(e) => {
                                                selectModule(eligibleModule);
                                                e.stopPropagation();
                                            }}
                                            checked={isModuleSelected(eligibleModule)}
                                        />
                                    </TableCell>
                                    <TableCell>{eligibleModule.commune}</TableCell>
                                    <TableCell>
                                        {eligibleModule.module_address} (
                                        {dataService.getDeviceType(eligibleModule.device_type)})
                                    </TableCell>
                                    <TableCell>{eligibleModule.concentrator}</TableCell>
                                    <TableCell>
                                        {eligibleModule.repeater} (
                                        {dataService.getDeviceType(
                                            eligibleModule.repeater_device_type
                                        )}
                                        )
                                    </TableCell>
                                </StyledTableBodyRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
