import { BirdzTitle } from '@applications-terrains/birdz-react-library';
import PurposesList from '../Purposes/PurposesList';
import { useSearchCampaignsAndPurposesContext } from '../SearchCampaignsAndPurposesContext';
import { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PagePaper from '../../../Tools/Pages/PagePaper';
import axios from 'axios';
import DotsLoader from '../../../Tools/Loaders/DotsLoader/DotsLoader';

const CampaignDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [campaignName, setCampaignName] = useState<string | null>(null);

    const { setCampaignDetails, campaignDetails } = useSearchCampaignsAndPurposesContext();

    useEffect(() => {
        if (campaignDetails?.name) {
            setCampaignName(campaignDetails?.name);
        } else if (!campaignDetails && id) {
            axios.get('/api/campaigns/' + id + '/').then((response) => {
                setCampaignName(response.data.name);
            });
        }

        return () => {
            setCampaignDetails(null);
            setCampaignName(null);
        };
    }, [campaignDetails, id, setCampaignDetails]);

    const targetId = campaignDetails?.id || id;

    return (
        <PagePaper
            title={
                <Box className="d-flex align-items-center overflow-hidden">
                    <Tooltip title={'retour liste'} placement="top">
                        <IconButton
                            id={`backToList`}
                            onClick={() => {
                                navigate(-1);
                            }}
                            sx={{ mr: 2 }}>
                            <ArrowBackIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <BirdzTitle className="m-0 d-flex w-100 overflow-hidden align-items-base" >
                        <Box sx={{ textWrap: 'nowrap' }}>Détails de la campagne : </Box>
                        <Tooltip title={campaignName} placement="top">
                        <Typography
                            sx={{ ml: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                            fontSize={18}>
                            {campaignName || <DotsLoader message="recherche en cours" />}
                        </Typography>
                        </Tooltip>
                    </BirdzTitle>
                </Box>
            }>
            {targetId && <PurposesList customFilters={'?campaign=' + targetId} />}
        </PagePaper>
    );
};

export default CampaignDetails;
