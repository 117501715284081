import React, { useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { launchExport } from './export';
import { ExportButton as ExportButtonType } from '@applications-terrains/birdz-react-library';
import { PaginationType } from '../../PageSizeSelector';
import UploadIcon from '@mui/icons-material/Upload';

const ExportButton = ({
    exportButton,
    endpoint,
    pagination
}: {
    exportButton: ExportButtonType;
    endpoint: string;
    pagination: PaginationType;
}) => {
    const [exportInProgress, setExportInProgress] = useState(false);
    return (
        <Tooltip title={exportButton.name} placement="top">
            <IconButton
                id={'export_button_' + exportButton.name}
                onClick={() => {
                    setExportInProgress(true);
                    launchExport({
                        ...exportButton,
                        onSuccess: (data) => {
                            setExportInProgress(false)
                            exportButton.onSuccess && exportButton.onSuccess(data)
                        },
                        endpoint,
                        pagination
                    });
                }}
                disabled={exportInProgress}
                key={exportButton.name}>
                {exportInProgress ? (
                    <CircularProgress size={16} thickness={4} sx={{ color: '#666666' }} />
                ) : exportButton.icon ? (
                    exportButton.icon
                ) : (
                    <UploadIcon />
                )}
            </IconButton>
        </Tooltip>
    );
};

export default ExportButton;
