import { Outlet } from 'react-router-dom';
import { SearchCampaignsAndPurposesContextProvider } from './Lists/SearchCampaignsAndPurposesContext';
import { Box } from '@mui/material';

const Sendings = () => {
    return (
        <Box className='h-100 d-flex flex-column'>
            <SearchCampaignsAndPurposesContextProvider>
                <Outlet />
            </SearchCampaignsAndPurposesContextProvider>
        </Box>
    );
};

export default Sendings;
