import React from 'react';
import { CampaignListItem } from './types';
import styled from 'styled-components';
import {
    Box,
    LinearProgress,
    Tooltip
} from '@mui/material';
import { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/CheckCircle';

import ErrorIcon from '@mui/icons-material/Error';

import PendingIcon from '@mui/icons-material/Pending';

import HideSourceIcon from '@mui/icons-material/HideSource';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PollIcon from '@mui/icons-material/Poll';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import SendIcon from '@mui/icons-material/Send';
import DrawIcon from '@mui/icons-material/Draw';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveIcon from '@mui/icons-material/Archive';
import StopIcon from '@mui/icons-material/StopCircle';

import { grey, green, red, blue, orange } from '@mui/material/colors';
import ExtendableChip from './ExtendableChip';

const greenColor = green[500];
const redColor = red[500];
const lightRedColor = red[300];
const greyColor = grey[300];
const blueColor = blue[300];
const orangeColor = orange[200];

const iconSize = '16px';
const icons = {
    created: <DrawIcon style={{ color: blueColor, fontSize: iconSize }} />,
    done: <CheckIcon style={{ color: greenColor, fontSize: iconSize }} />,
    error: <ErrorIcon style={{ color: redColor, fontSize: iconSize }} />,
    pending: <PendingIcon style={{ color: blueColor, fontSize: iconSize }} />,
    scheduled: <ScheduleIcon style={{ color: blueColor, fontSize: iconSize }} />,
    polling_purposes: <PollIcon style={{ color: blueColor, fontSize: iconSize }} />,
    creating_purposes: <FiberNewIcon style={{ color: blueColor, fontSize: iconSize }} />,
    sending_purposes: <SendIcon style={{ color: blueColor, fontSize: iconSize }} />,
    deleted: <DeleteForeverIcon style={{ color: orangeColor, fontSize: iconSize }} />,
    stopped: <StopIcon style={{ color: lightRedColor, fontSize: iconSize }} />,
    archived: <ArchiveIcon style={{ color: blueColor, fontSize: iconSize }} />,
    default: <HideSourceIcon style={{ color: greyColor, fontSize: iconSize }} />
};

const purposeStatuses = {
    queued: { label: '', bgColor: '#A2A2A2', textColor: 'white' },
    scheduled: { bgColor: blueColor, textColor: 'white' },
    processing: { bgColor: '#AD8BC9', textColor: 'white' },
    pending: { bgColor: '#6DCCDA', textColor: 'white' },
    retrying: { bgColor: '#ED97CA', textColor: 'white' },
    processed: { bgColor: '#729ECE', textColor: 'white' },
    cancelled: { bgColor: '#CCCC5C', textColor: 'black' },
    expired: { bgColor: '#FF9E4A', textColor: 'white' },
    command_succeed: { bgColor: '#67BF5C', textColor: 'white' },
    command_failed: { bgColor: '#A8786E', textColor: 'white' },
    error: { bgColor: redColor, textColor: 'white' },
    stopped: { bgColor: lightRedColor, textColor: 'white' }
};

const expand = keyframes`
 0% { width: 0%; }
 100% {width: 180px; }
`;
const reveal = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`;

const StyledProgressBar = styled.div`
    margin-top: 0.5rem;
    width: 180px;
    height: 16px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    // animation-name: ${expand};
    // animation-duration: 0.7s;
`;

const StyledDisplayPercentage = styled.div<{ color: string }>`
    position: absolute;
    z-index: 1;
    font-size: 0.7rem;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${(props) => props.color};

    // animation-name: ${reveal};
    // animation-duration: 1.5s;
`;


const DisplayStatus = ({ campaign }: { campaign: CampaignListItem }) => {
    const { t } = useTranslation();

    const getPercentage = (partialValue: number | undefined, totalValue: number) => {
        if (!partialValue) return 0;
        const percentage = (100 * partialValue) / totalValue;
        return percentage > 0 && percentage < 1 ? '<1' : Math.round(percentage);
    };

    const values = Object.entries(purposeStatuses)
        .map(([key, colors]) => {
            const rawValue = campaign.purposes_stats[key as keyof typeof campaign.purposes_stats];
            return rawValue
                ? {
                      [key]: {
                          percentage: getPercentage(rawValue, campaign.purposes_count),
                          colors,
                          rawValue
                      }
                  }
                : undefined;
        })
        .filter((el) => el);

    const icon = icons[campaign.state as keyof typeof icons];
    return (
        <>
            <ExtendableChip
                icon={icon}
                label={
                    t('status.' + campaign.state.toLowerCase()) +
                    `${campaign.progress_rate !== 100 ? ` (${campaign.progress_rate + '%'})` : ''}`
                }
                title={
                    campaign.error_message ||
                    `${campaign.purposes_count} intention${campaign.purposes_count > 1 ? 's' : ''}`
                }
                style={{ backgroundColor: 'transparent', border: '1px solid #bdbdbd' }}
                maxNbOfChar={20}
            />

            {values.length > 0 && (
                <StyledProgressBar>
                    {values.map((val) => {
                        const [key, value] = Object.entries(val as object)[0];
                        return (
                            <Tooltip
                                key={campaign.id + key + value}
                                title={t('status.' + key.toLowerCase()) + ` : ` + value.rawValue}
                                placement="top"
                                slotProps={{
                                    popper: {
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -10]
                                                }
                                            }
                                        ]
                                    }
                                }}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: value.percentage + '%',
                                        minWidth: '20px',
                                        cursor: 'default'
                                    }}>
                                    <StyledDisplayPercentage color={value.colors.textColor}>
                                        {value.percentage + '%'}
                                    </StyledDisplayPercentage>
                                    <LinearProgress
                                        variant="determinate"
                                        value={100}
                                        className="linearProgress"
                                        sx={{
                                            height: '24px',
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: value.colors.bgColor
                                            }
                                        }}
                                    />
                                </Box>
                            </Tooltip>
                        );
                    })}
                </StyledProgressBar>
            )}
        </>
    );
};

export default DisplayStatus;
