import React from 'react';
import { AppPaper } from '@applications-terrains/birdz-react-library';
import { Outlet } from 'react-router-dom';

const Racsup = () => (
    <AppPaper sx={{pt:2}}>
        <Outlet />
    </AppPaper>
);

export default Racsup